import React from 'react';

function MediaAssetManagerTitle()
{
    return(
      <div className='w-layout-grid layout10_component-2'>
      <div className='layout10_content'>                
    <div
        data-w-id='5f9f8480-29e7-c633-2304-3c68900e636d'
        className='margin-bottom margin-small media-asset-padd margin-left'
      >
        <h1 className='heading-4 globalinputh'>
          <span
            data-w-id='5f9f8480-29e7-c633-2304-3c68900e636f'
            className='text-span-4'
          >
            Media Asset Manager
          </span>
        </h1>
      </div>
      </div>
      </div>
      );
}
export default MediaAssetManagerTitle;