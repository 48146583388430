import React from 'react'

function IssueInvestigationSection () {
  return (
    <section className='section_layout10'>
      <div className='padding-global'>
        <div className='container-large'>
          <div className='padding-section-large-2 automaticclientpad'>
            <div
              data-w-id='02ac780a-fcd9-b5c3-654d-530b14bf73a1'
              className='w-layout-grid layout10_component-2 automaticclientbackround'
            >
              <div
                id='w-node-_02ac780a-fcd9-b5c3-654d-530b14bf73a2-f6604ea5'
                className='layout10_content'
              >
                <div
                  data-w-id='02ac780a-fcd9-b5c3-654d-530b14bf73a3'
                  className='margin-bottom margin-small'
                >
                  <h1 className='heading-4'>
                    <span className='text-span-4 automaticcliendheader'>
                      Automatic Client Application Issue Investigation
                    </span>
                  </h1>
                </div>
                <div className='margin-bottom margin-medium'>
                  <p
                    data-w-id='02ac780a-fcd9-b5c3-654d-530b14bf73a8'
                    className='text-size-medium-2 dataprotectionparagraph'
                  >
                    <span className='text-span-7 dataprotectionparag'>
                      
                      Our innovative solution enhances client application development without disrupting the process. By injecting diagnostic and monitoring codes into applications and their environments at deployment time, we avoid changes to the code repository, and  our system simulates test cases, collects data, analyzes logs and code, and automatically investigates issues, ensuring efficient and effective diagnostics.
                    </span>
                  </p>
                  <div
                    data-w-id='de4beb55-033f-242c-b9cd-b0be2a483141'
                    className='div-block-3 automatic-client-paddingdiv w-clearfix'
                  >
                    <img
                      src='images/Polygon-2.svg'
                      loading='lazy'
                      alt=''
                      className='image-6'
                    />
                    <div className='text-block-5'>
                      <span className='text-span-8'>
                        Automatic Metrics and Log Collection:  
                      </span>
                      <span>
                        
                        Our system seamlessly gathers crucial metrics and logs, including HTTP(S) communication logs, from client applications running on actual devices without requiring any changes to the code repository.

                      </span>
                    </div>
                  </div>
                  <div
                    data-w-id='105d4ccd-b679-1092-1c8d-2348a27e15df'
                    className='div-block-3 automatic-client-paddingdiv w-clearfix'
                  >
                    <img
                      src='images/Polygon-2.svg'
                      loading='lazy'
                      alt=''
                      className='image-6'
                    />
                    <div className='text-block-5'>
                      <span className='text-span-8'>
                        End-to-End Use Case Testing:{' '}
                      </span>
                      <span>                        
                        Our solution enables automated end-to-end use case testing and test automation, ensuring thorough and consistent testing of application functionalities without manual intervention or changes to the code repository.
                      </span>
                    </div>
                  </div>
                  <div
                    data-w-id='3c6ffe04-1f7e-6f29-5152-c8bafd3a2f83'
                    className='div-block-3 automatic-client-paddingdiv w-clearfix'
                  >
                    <img
                      src='images/Polygon-2.svg'
                      loading='lazy'
                      alt=''
                      className='image-6'
                    />
                    <div className='text-block-5'>
                      <span className='text-span-8'>
                        Automatic Issue Investigation:{' '}
                      </span>
                      <span>
                        With our system, we automate the investigation of issues by simulating test cases, collecting data, analyzing logs against code, and diagnosing root cause of the issues identified, ensuring efficient and effective diagnostics.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IssueInvestigationSection
