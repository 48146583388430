import React from 'react'

function MobileAuthenticationSection () {
  return (
    <section className='section_layout306'>
      <div className='padding-global'>
        <div className='container-large'>
          <div className='padding-section-large-2 mobileauthpadding'>
            <div className='layout306_component'>
              <div className='margin-bottom margin-xxlarge'>
                <div className='max-width-large'>
                  <div
                    data-w-id='750ef52f-9867-1cee-f8f9-79438c99c1a1'
                    className='margin-bottom margin-small margin-heading'
                  >
                    <h2
                      data-w-id='750ef52f-9867-1cee-f8f9-79438c99c1a2'
                      className='heading-2'
                    >
                      Mobile Authentication
                    </h2>
                  </div>
                  <p
                    data-w-id='750ef52f-9867-1cee-f8f9-79438c99c1a4'
                    className='text-size-medium mobile-text-padding'
                  >
                    
                    
                    Our mobile authentication solutions integrate diverse methods such as password-based authentication, two-factor authentication, and cryptographic key-based authentication, delivering a secure and seamless user experience without compromising convenience or performance, resulting in a versatile range of solutions.
                  </p>
                </div>
              </div>
              <div className='w-layout-grid layout306_list mobile-authentication-padding'>
                <div
                  data-w-id='750ef52f-9867-1cee-f8f9-79438c99c1a7'
                  className='layout306_item'
                >
                  <div className='margin-bottom margin-small'>
                    <div className='layout178_image-wrapper'>
                      <img
                        src='assets/mobile-authentication-pin.png'
                        loading='lazy'
                        width='1973'
                        alt=''
                        className='layout306_image roundingimages'
                      />
                    </div>
                  </div>
                  <div className='margin-bottom margin-xsmall'>
                    <h3 className='heading-style-h5 mobileauthentication'>
                      Unified Authentication
                    </h3>
                  </div>
                  <p className='paragraph-3'>
                    Integrating multiple authentication methods to ensure a secure and seamless user experience across various applications and devices.
                  </p>
                </div>
                <div
                  data-w-id='750ef52f-9867-1cee-f8f9-79438c99c1b0'
                  className='layout306_item'
                >
                  <div className='margin-bottom margin-small'>
                    <div className='layout178_image-wrapper'>
                      <img
                        src='assets/end-to-end-encryption.png'
                        loading='lazy'
                        width='1973'
                        alt=''
                        className='layout306_image roundingimages'
                      />
                    </div>
                  </div>
                  <div className='margin-bottom margin-xsmall'>
                    <h3 className='heading-style-h5 mobileauthentication'>
                      End-to-End Encryption
                    </h3>
                  </div>
                  <p className='paragraph-3'>
                    Securing the transfer of information between mobile devices and applications using end-to-end encryption.
                    
                  </p>
                </div>
                <div
                  data-w-id='750ef52f-9867-1cee-f8f9-79438c99c1b9'
                  className='layout306_item'
                >
                  <div className='margin-bottom margin-small'>
                    <div className='layout178_image-wrapper'>
                      <img
                        src='assets/storage=mobile-processor.png'
                        loading='lazy'
                        width='1728'
                        sizes='(max-width: 767px) 90vw, (max-width: 991px) 43vw, (max-width: 1439px) 21vw, 296px'
                        alt=''
                        className='layout306_image roundingimages'
                      />
                    </div>
                  </div>
                  <div className='margin-bottom margin-xsmall'>
                    <h3 className='heading-style-h5 mobileauthentication'>
                      User-Controlled
                    </h3>
                  </div>
                  <p className='paragraph-3'>
                  Empowering users to securely store and manage their authentication data and encryption keys, eliminating dependencies on the security of providers.
                    
                  </p>
                </div>
                <div
                  data-w-id='750ef52f-9867-1cee-f8f9-79438c99c1c2'
                  className='layout306_item'
                >
                  <div className='margin-bottom margin-small'>
                    <div className='layout178_image-wrapper'>
                      <img
                        src='assets/cross-platform.png'
                        loading='lazy'
                        alt=''
                        className='layout306_image roundingimages'
                      />
                    </div>
                  </div>
                  <div className='margin-bottom margin-xsmall'>
                    <h3 className='heading-style-h5 mobileauthentication'>
                      Cross-Platform 
                    </h3>
                  </div>
                  <p className='paragraph-3'>
                  Extending applications running on various platforms, including computers, set-top boxes, smart TVs, IoT devices, and self-service machines, to incorporate mobile authentication capabilities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default MobileAuthenticationSection
