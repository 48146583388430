import React from 'react'

function DeviceSimulatorTitle () {
    return (
        <div
                  data-w-id='dce1eda5-1845-ce6a-79e6-0ef264bcf009'
                  className='margin-bottom margin-small devicesimulatorheading margin-left'
                >
                  <h1 className='heading-4 globalinputh'>
                    <span className='text-span-4'>The Device Simulator</span>
                  </h1>
                </div>
    );

}
export default DeviceSimulatorTitle;