import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  flex-direction: column;
  justify-content: flex-end;    
  margin-right: 7.5%;
  display: flex;
`;

function GlobalInputDescription(){
    return (
    <Container>
        
        <div className='margin-top'>
          <div className='button-group'>
            <a
              data-w-id='f2eea542-7d8e-d76a-cb21-10886b20f4fa'
              href='https://globalinput.co.uk/'
              className='link-block-2 w-inline-block'
            >
              
              <div className='text-block-4'>Try Global Input App</div>
              <img
                src='images/Frame-47576.svg'
                loading='lazy'
                alt=''
              />
            </a>
            
          </div>
        </div>
        </Container>
      )

}
export default GlobalInputDescription;


/*
<div
          data-w-id='f2eea542-7d8e-d76a-cb21-10886b20f4f4'
          className='margin-bottom'
        >
          
          <p className='text-size-medium-2 datasecurityparag'>
            <span className='text-span-7 datasecurityspan'>
              The Global Input App is a versatile and secure system
              designed to extend the functionality of applications running various
              device platforms, including computers, Smart TVs, streaming
              boxes, and IoT devices, adding robust mobile
              operability features,
            </span>
          </p>
        </div>
        */