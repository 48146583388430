import React from 'react'

function SolutionSection () {
  return (
    <section id='learn-more' className='section_layout306'>
      <div className='padding-global'>
        <div className='container-large'>
          <div className='padding-section-large-2 cuttin-edge-padding'>
            <div className='layout306_component'>
              <div className='margin-bottom margin-xxlarge cuttin-edgle-margins'>
                <div className='max-width-large'>
                  <div
                    data-w-id='750ef52f-9867-1cee-f8f9-79438c99c1a1'
                    className='margin-bottom margin-small'
                  >
                    <h2
                      data-w-id='750ef52f-9867-1cee-f8f9-79438c99c1a2'
                      className='heading-2'
                    >                      
                      Transformative Solutions to Modern Challenges
                    </h2>
                  </div>
                  <p
                    data-w-id='750ef52f-9867-1cee-f8f9-79438c99c1a4'
                    className='text-size-medium cuttiing-edge-paragraph'
                  >                                      
                    We offer comprehensive services across interrelated domains, including data protection and GDPR compliance that prioritise user control, authentication solutions that enhance both user experience and security, data encryption focused on securing encryption keys, automatic client application issue investigation that improves development processes without requiring changes to code repositories, and media asset management and streaming designed to minimise vendor lock-in for greater flexibility and cross-platform interoperability.
                  </p>
                </div>
              </div>
              
              <div className='w-layout-grid layout306_list cuttingedgelayout cuttin-edge-layout'>
                <div
                  data-w-id='750ef52f-9867-1cee-f8f9-79438c99c1a7'
                  className='layout306_item'
                >
                  <div className='margin-bottom margin-small'>
                    <div className='layout178_image-wrapper'>
                      <img
                        src='assets/gdpr.png'
                        loading='eager'
                        alt=''
                        className='layout306_image roundingimages'
                      />
                    </div>
                  </div>
                  <div className='margin-bottom margin-xsmall'>
                    <h3 className='heading-style-h5 cuttingheading'>
                      Data Protection and GDPR Compliance
                    </h3>
                  </div>
                  <p className='paragraph-3'>
                  Focused on giving users control over their personal data and simplifying the process of GDPR compliance.
                  </p>
                </div>
                <div
                  data-w-id='750ef52f-9867-1cee-f8f9-79438c99c1b0'
                  className='layout306_item'
                >
                  <div className='margin-bottom margin-small'>
                    <div className='layout178_image-wrapper'>
                      <img
                        src='assets/mobile-authentication-pin.png'
                        loading='eager'
                        alt=''
                        className='layout306_image roundingimages'
                      />
                    </div>
                  </div>
                  <div className='margin-bottom margin-xsmall'>
                    <h3 className='heading-style-h5 cuttingheading'>
                      Mobile Authentication
                    </h3>
                  </div>
                  <p className='paragraph-3'>
                  Dedicated to simplifying the user experience while improving security by integrating various authentication methods and minimizing disruptions to the existing authentication infrastructure.
                  </p>
                </div>
                <div
                  data-w-id='750ef52f-9867-1cee-f8f9-79438c99c1b9'
                  className='layout306_item'
                >
                  <div className='margin-bottom margin-small'>
                    <div className='layout178_image-wrapper'>
                      <img
                        src='assets/data-security.png'
                        loading='eager'
                        width='1728'
                        sizes='(max-width: 767px) 90vw, (max-width: 991px) 43vw, (max-width: 1439px) 16vw, 230.390625px'
                        alt=''
                        className='layout306_image roundingimages'
                      />
                    </div>
                  </div>
                  <div className='margin-bottom margin-xsmall'>
                    <h3 className='heading-style-h5 cuttingheading'>
                      Data security
                    </h3>
                  </div>
                  <p className='paragraph-3'>
                  Maximizing security using multi-layered and hierarchical encryption, focusing on securing encryption keys, and leveraging mobile encryption and decryption with user consent.
                  
                    <br />‍
                  </p>
                </div>
                <div
                  data-w-id='750ef52f-9867-1cee-f8f9-79438c99c1c2'
                  className='layout306_item'
                >
                  <div className='margin-bottom margin-small'>
                    <div className='layout178_image-wrapper'>
                      <img
                        src='assets/issue-iinvestigation.png'
                        loading='eager'
                        sizes='(max-width: 767px) 90vw, (max-width: 991px) 43vw, (max-width: 1439px) 16vw, 230.40625px'
                        alt=''
                        className='layout306_image roundingimages'
                      />
                    </div>
                  </div>
                  <div className='margin-bottom margin-xsmall'>
                    <h3 className='heading-style-h5 cuttingheading'>
                      Automatic Client Application Issue Investigation
                    </h3>
                  </div>
                  <p className='paragraph-3'>
                  
                  
                  Automating the diagnosis process without impacting development by injecting diagnostic codes into applications and their running environments at deployment time.
                  </p>
                </div>
                <div
                  id='w-node-f696e908-0784-fb33-e93e-b140511b8488-7d95797d'
                  data-w-id='f696e908-0784-fb33-e93e-b140511b8488'
                  className='layout306_item'
                >
                  <div className='margin-bottom margin-small'>
                    <div className='layout178_image-wrapper'>
                      <img
                        src='assets/media-streaming.png'
                        loading='eager'
                        sizes='(max-width: 767px) 90vw, (max-width: 991px) 43vw, (max-width: 1439px) 16vw, 230.390625px'
                        alt=''
                        className='layout306_image roundingimages'
                      />
                    </div>
                  </div>
                  <div className='margin-bottom margin-xsmall'>
                    <h3 className='heading-style-h5 cuttingheading'>
                      End-to-End Media streaming Solutions
                    </h3>
                  </div>
                  <p className='paragraph-3'>
                  Designed to minimise vendor lock-in in media asset management, media publishing, and video streaming platforms, with enhanced flexibility and reduced costs.
                  </p>
                </div>

                <div className='margin-top margin-medium'>
                  <div className='button-group'>
                    <a
                      href='/solutions#solutionBanner'
                      className='link-block-2 w-inline-block'
                    >
                      <div className='text-block-4'>Learn more</div>
                      <img src='images/Frame-47576.svg' loading='lazy' alt='' />
                    </a>
                    
                  </div>                  
                </div>




              </div>
              
              



            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SolutionSection
