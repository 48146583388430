import React from 'react'
import styled from 'styled-components'



function ProductSection () {
  return (
    <section className='section_layout10'>
      <div className='padding-global'>
        <div className='container-large'>
          <div className='padding-section-large-2 elevate-your-digital-experience'>
            <div className='w-layout-grid layout10_component-2'>
              <div
                id='w-node-_5f9f8480-29e7-c633-2304-3c68900e636c-7d95797d'
                className='layout10_content elevate-your-digital-heading'
              >
                <div
                  data-w-id='5f9f8480-29e7-c633-2304-3c68900e636d'
                  className='margin-bottom margin-small'
                >
                  <h2 className='heading-2'>
                    <span
                      data-w-id='5f9f8480-29e7-c633-2304-3c68900e636f'
                      className='text-span-4'
                    >
                      
                      Enriching Software Systems with Our Innovative Software Products
                      
                    </span>
                  </h2>
                </div>
                <div
                  data-w-id='5f9f8480-29e7-c633-2304-3c68900e637d'
                  className='margin-bottom margin-medium'
                >
                  <p className='text-size-medium-2 margin-left-paragraph'>
                    <span
                      data-w-id='5f9f8480-29e7-c633-2304-3c68900e637f'
                      className='text-span-7'
                    >
                      We strive to revolutionize digital interactions across devices through our innovative software products that are designed to extend and enhance the capabilities of existing applications, platforms, and devices, enabling businesses to deliver a seamless and secure digital experience to their customers.
                    </span>
                  </p>
                </div>
                
              </div>
            </div>
            <div
              data-w-id='c7f598d2-0ac8-9d50-5f79-c102be7b98a8'
              className='w-layout-grid layout10_component-5 elevatebackground'
            >
              <div className='layout10_content'>
                <div className='w-layout-grid layout10_item-list-5'>
                  <div
                    data-w-id='c7f598d2-0ac8-9d50-5f79-c102be7b98b4'
                    className='layout10_item'
                  >
                    <div className='margin-bottom margin-xsmall'>
                      <img src='images/laptop.svg' loading='eager' alt='' />
                      <img
                        src='images/icon.svg'
                        loading='lazy'
                        alt=''
                        className='icon-1x1-medium'
                      />
                    </div>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-global-input-app'>
                        Global Input App
                      </h3>
                    </div>
                    <p className='paragraphglobalinputapp'>
                      The Global Input App is a versatile system
                      designed to extend the applications running on devices devices like computers, Smart TVs, streaming boxes, and IoT to have robust mobile operability features.
                    </p>
                  </div>
                  <div
                    data-w-id='c7f598d2-0ac8-9d50-5f79-c102be7b98bc'
                    className='layout10_item'
                  >
                    <div className='margin-bottom margin-xsmall'>
                      <img src='images/management.svg' loading='eager' alt='' />
                      <img
                        src='images/icon.svg'
                        loading='lazy'
                        alt=''
                        className='icon-1x1-medium'
                      />
                    </div>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-global-input-app'>
                        The Device Simulator
                      </h3>
                    </div>
                    <p className='paragraphglobalinputapp'>
                    This is a Chrome extension for simulating web environments of video streaming platforms, such as PS WebMaf, Xbox, FireTV etc., to facilitate the the development and and testing of video streaming applications.
                    </p>
                  </div>
                </div>
                <div className='w-layout-grid layout10_item-list-5'>
                  <div
                    data-w-id='3553fcc6-17e7-425d-b144-02a0c061aa02'
                    className='layout10_item'
                  >
                    <div className='margin-bottom margin-xsmall'>
                      <img src='images/Group1.svg' loading='eager' alt='' />
                      <img
                        src='images/icon.svg'
                        loading='lazy'
                        alt=''
                        className='icon-1x1-medium'
                      />
                    </div>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-global-input-app'>
                        Instrumentation System
                      </h3>
                    </div>
                    <p className='paragraphglobalinputapp'>
                    The Instrumentation System is a sophisticated tool designed to inject applications and their running environments with required functionalities at deployment time without requiring any changes to the application code repositories for diagnosis and monitoring of purposes.                       
                    </p>
                  </div>
                  <div
                    data-w-id='3553fcc6-17e7-425d-b144-02a0c061aa0a'
                    className='layout10_item'
                  >
                    <div className='margin-bottom margin-xsmall'>
                      <img src='images/Group.svg' alt='' />
                      <img
                        src='images/icon.svg'
                        loading='lazy'
                        alt=''
                        className='icon-1x1-medium'
                      />
                    </div>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-global-input-app'>
                        Media Asset Manager
                      </h3>
                    </div>
                    <p className='paragraphglobalinputapp'>
                    The Media Asset Manager is a comprehensive system designed to minimize vendor lock-in in managing and publishing video assets to platforms, enhancing operational agility and freedom in content management.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            

            
            <div className='margin-top margin-medium'>
                  <div className='button-group'>
                    <a
                      href='/products#productBanner'
                      className='link-block-2 w-inline-block'
                    >
                      <div className='text-block-4'>Learn more</div>
                      <img src='images/Frame-47576.svg' loading='lazy' alt='' />
                    </a>
                    
                  </div>                  
                </div>
              


          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductSection
