import React from 'react'

function MediaStreamingSection () {
  return (
    <section className='section_layout306'>
      <div className='padding-global'>
        <div className='container-large'>
          <div className='padding-section-large-2 endtoendpadding'>
            <div className='layout306_component'>
              <div className='margin-bottom margin-xxlarge'>
                <div className='max-width-large'>
                  <div
                    data-w-id='49d3e862-78d9-11dd-54b1-461ca422bb38'
                    className='margin-bottom margin-small end-to-end-padding'
                  >
                    <h2 className='heading-2'>
                      End-to-End Media streaming Solutions
                    </h2>
                  </div>
                  <p
                    data-w-id='49d3e862-78d9-11dd-54b1-461ca422bb3b'
                    className='text-size-medium end-to-endpadding'
                  >                                        
                    Designed to minimize vendor lock-in, offering comprehensive centralized media asset management capabilities, integrating with publishing platforms, and providing media player solutions across various platforms.
                  </p>
                </div>
              </div>
              <div className='w-layout-grid layout306_list endtoendgrid'>
                <div
                  data-w-id='49d3e862-78d9-11dd-54b1-461ca422bb3e'
                  className='layout306_item'
                >
                  <div className='margin-bottom margin-small'>
                    <div className='layout178_image-wrapper'>
                      <img
                        src='assets/media-streaming.png'
                        loading='lazy'
                        alt=''
                        className='layout306_image roundingimages'
                      />
                    </div>
                  </div>
                  <div className='margin-bottom margin-xsmall'>
                    <h3 className='heading-style-h5 mobileauthentication'>
                      Media Asset Management
                    </h3>
                  </div>
                  <p className='paragraph-3'>
                  Comprehensive Media Asset Management designed to minimize vendor lock-in, featuring extensive metadata management, scheduling information, ad-break data, transcoding of media assets, and more.
                     

                     
                  </p>
                </div>
                <div
                  data-w-id='49d3e862-78d9-11dd-54b1-461ca422bb47'
                  className='layout306_item'
                >
                  <div className='margin-bottom margin-small'>
                    <div className='layout178_image-wrapper'>
                      <img
                        src='assets/platform-integration.png'
                        loading='lazy'
                        sizes='(max-width: 767px) 90vw, (max-width: 991px) 43vw, (max-width: 1439px) 21vw, 296px'
                        alt=''
                        className='layout306_image roundingimages'
                      />
                    </div>
                  </div>
                  <div className='margin-bottom margin-xsmall'>
                    <h3 className='heading-style-h5 mobileauthentication'>
                      Integration with Publishing Platforms
                    </h3>
                  </div>
                  <p className='paragraph-3'>
                  Integrates with existing back-end services and popular media publishing platforms, ensuring scalability and adaptability for future requirements.
                  </p>
                </div>
                <div
                  data-w-id='49d3e862-78d9-11dd-54b1-461ca422bb50'
                  className='layout306_item'
                >
                  <div className='margin-bottom margin-small'>
                    <div className='layout178_image-wrapper'>
                    
                      <img
                        src='assets/platform-players.png'
                        loading='lazy'
                        sizes='(max-width: 767px) 90vw, (max-width: 991px) 43vw, (max-width: 1439px) 21vw, 296px'
                        
                        alt=''
                        className='layout306_image roundingimages'
                      />
                    </div>
                  </div>
                  <div className='margin-bottom margin-xsmall'>
                    <h3 className='heading-style-h5 mobileauthentication'>
                      Platform Players
                    </h3>
                  </div>
                  <p className='paragraph-3'>
                      
                  Designed to handle varying capabilities in both backend and frontend environments with comprehensive diagnostics and monitoring of the streaming processes.
                  </p>
                </div>
                <div
                  data-w-id='49d3e862-78d9-11dd-54b1-461ca422bb59'
                  className='layout306_item'
                >
                  <div className='margin-bottom margin-small'>
                    <div className='layout178_image-wrapper'>
                      <img
                        src='assets/second-screen.png'
                        loading='lazy'
                        alt=''
                        className='layout306_image roundingimages'
                      />
                    </div>
                  </div>
                  <div className='margin-bottom margin-xsmall'>
                    <h3 className='heading-style-h5 mobileauthentication'>
                      Second Screen Solutions
                    </h3>
                  </div>
                  <p className='paragraph-3'>
                  Our intuitive and effective solutions offer synchronized content, interactive features, and real-time updates across multiple devices, all without requiring additional infrastructure or backend changes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MediaStreamingSection
