import React from "react";

function GlobalInputAppFeaturesSection() {
  return (
  <div className='layout458_list'>
  <div
    data-w-id='dae45b6c-b14a-0233-c200-d69247794942'
    className='layout458_item1'
  >
    <div className='margin-bottom margin-medium'>
      <div className='layout458_image-wrapper'>
        <img
          src='assets/person-holding-mobile-2.png'
          loading='lazy'
          sizes='(max-width: 767px) 90vw, (max-width: 991px) 28vw, (max-width: 1439px) 27vw, 394.65625px'
          
          alt=''
          className='layout458_image imageglobalinputapp'
        />
      </div>
    </div>
    <div className='margin-bottom margin-xsmall'>
      <h3 className='heading-style-h4 headingmobileoperability'>
        Mobile Integrations
      </h3>
    </div>
    <p className='mobileoperabilitypar'>
    Enhance applications with seamless mobile integrations, enabling intuitive interactions and secure operations directly from users’ mobile devices, allowing for mobile authentication, control, and collaboration. 
    </p>
  </div>
  <div
    data-w-id='dae45b6c-b14a-0233-c200-d6924779494b'
    className='layout458_item2'
  >
    <div className='margin-bottom margin-medium'>
      <div className='layout458_image-wrapper'>
        <img
          src='assets/mobile-authentication-pin.png'
          loading='lazy'
          sizes='(max-width: 767px) 90vw, (max-width: 991px) 28vw, (max-width: 1439px) 27vw, 394.671875px'
          
          alt=''
          className='layout458_image imageglobalinputapp'
        />
      </div>
    </div>
    <div className='margin-bottom margin-xsmall'>
      <h3 className='heading-style-h4 headingmobileoperability'>
        Mobile Authentication
      </h3>
    </div>
    <p className='mobileoperabilitypar'>
       Extending the existing applications to have the capability to allow users to user their mobile devices to authenticate themselves, to achive increased security without sactificing user experience.
       
    </p>
  </div>
  <div
    data-w-id='dae45b6c-b14a-0233-c200-d69247794954'
    className='layout458_item3'
  >
    <div className='margin-bottom margin-medium'>
      <div className='layout458_image-wrapper'>
        <img
          src='assets/globalinput-second-screen.png'
          loading='lazy'
          sizes='(max-width: 767px) 90vw, (max-width: 991px) 28vw, (max-width: 1439px) 27vw, 394.65625px'
          
          alt=''
          className='layout458_image imageglobalinputapp'
        />
      </div>
    </div>
    <div className='margin-bottom margin-xsmall'>
      <h3 className='heading-style-h4 headingmobileoperability'>
        Second Screen 
      </h3>
    </div>
    <p className='mobileoperabilitypar'>
      Extending the existing applications to have a secure second screen functionality without the need for any back-end development, allowing users to use their mobile devices as complementary screens for enhanced interaction with streaming devices, IoT devices, self-service machines, and other devices.
       

    </p>
  </div>
</div>);
}

export default GlobalInputAppFeaturesSection;