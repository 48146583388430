import React from "react";

function DeviceFeature()
{
    return (<div
        data-w-id='dce1eda5-1845-ce6a-79e6-0ef264bcf01c'
        className='w-layout-grid layout10_component-5 the-device-simulator'
      >
        <div className='layout10_content'>
          <div className='w-layout-grid layout10_item-list-5'>
            <div
              data-w-id='dce1eda5-1845-ce6a-79e6-0ef264bcf01f'
              className='layout10_item'
            >
              <div className='margin-bottom margin-xsmall'>
                <img src='images/1laptop.svg' loading='lazy' alt='' />
                <img
                  src='images/icon.svg'
                  loading='lazy'
                  alt=''
                  className='icon-1x1-medium'
                />
              </div>
              <div className='margin-bottom margin-xsmall'>
                <h6 className='heading-global-input-app'>
                  Simulating Web Environment
                </h6>
              </div>
              <p className='paragraphglobalinputapp'>              
              A Chrome Extension that simulates the web environments of various devices, including PlayStation WebMAF, FireTV, and set-top boxes.
              </p>
            </div>
            <div
              data-w-id='dce1eda5-1845-ce6a-79e6-0ef264bcf028'
              className='layout10_item'
            >
              <div className='margin-bottom margin-xsmall'>
                <img src='images/2Vector.svg' loading='lazy' alt='' />
                <img
                  src='images/icon.svg'
                  loading='lazy'
                  alt=''
                  className='icon-1x1-medium'
                />
              </div>
              <div className='margin-bottom margin-xsmall'>
                <h6 className='heading-global-input-app'>
                  Simulating Remote Control
                </h6>
              </div>
              <p className='paragraphglobalinputapp'>
                Simulating the remote control of the device, full testing of the application can be performed without the need for the actual device.
              </p>
            </div>
          </div>
          <div className='w-layout-grid layout10_item-list-5'>
            <div
              data-w-id='dce1eda5-1845-ce6a-79e6-0ef264bcf032'
              className='layout10_item'
            >
              <div className='margin-bottom margin-xsmall'>
                <img src='images/3api.svg' loading='lazy' alt='' />
                <img
                  src='images/icon.svg'
                  loading='lazy'
                  alt=''
                  className='icon-1x1-medium'
                />
              </div>
              <div className='margin-bottom margin-xsmall'>
                <h6 className='heading-global-input-app'>
                Simulating Platform APIs
                </h6>
              </div>
              <p className='paragraphglobalinputapp'>
                Simulating the platform and device-specific APIs to facilitate a comprehensive testings including difference versions of the platform.
                
              </p>
            </div>
            <div
              data-w-id='dce1eda5-1845-ce6a-79e6-0ef264bcf03b'
              className='layout10_item'
            >
              <div className='margin-bottom margin-xsmall'>
                <img src='images/4computer.svg' loading='lazy' alt='' />
                <img
                  src='images/icon.svg'
                  loading='lazy'
                  alt=''
                  className='icon-1x1-medium'
                />
              </div>
              <div className='margin-bottom margin-xsmall'>
                <h6 className='heading-global-input-app'>
                Persistent Simulation
                </h6>
              </div>
              <p className='paragraphglobalinputapp'>
              Automatically applying the correct simulation environment for each web application developed for that platform.
              </p>
            </div>
          </div>
        </div>
      </div>);
}
export default DeviceFeature;