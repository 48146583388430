import React from   'react';

import MediaAssetManagerUseCases from './MediaAssetManagerUseCases';

import MediaAssetManagerTitle from './MediaAssetManagerTitle';
import MediaAssetManagerDescription from './MediaAssetManagerDescription';
import MediaAssetManagerFeatures from './MediaAssetManagerFeatures';




const MediaAssetManagerSection = () => {
  return (
    
      <section className='section_layout10 meadiaassetpa'>      
        <div className='container-large'>                      
                <MediaAssetManagerTitle/>                                                                         
                <MediaAssetManagerFeatures/>          
                <MediaAssetManagerUseCases />      
              
        </div>      
      
      
    </section>
      
    
  );


}

export default MediaAssetManagerSection;