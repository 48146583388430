import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
flex-direction: column;
  justify-content: flex-end;
  display: flex;

`;




function InstrumentationDescription(){

return(

  <Container>
    
    <div className='margin-top margin-medium'>
      <div className='button-group'>
        
        <a
          data-w-id='a787e6ab-1c79-dbf5-a735-2f94a52b5da0'
          href='contact-us.html'
          className='link-block-2 link-blok-3 w-inline-block'
        >
          <div className='text-block-4'>Contact us</div>
          <img src='images/Vector.svg' loading='lazy' alt='' />
        </a>
      </div>
    </div>
    </Container>
);
}

export default InstrumentationDescription;