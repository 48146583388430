import React from 'react';


function InstrumentationFeature(){
    return (
        <div className='layout458_list'>
                <div
                  data-w-id='a787e6ab-1c79-dbf5-a735-2f94a52b5da5'
                  className='layout458_item1'
                >
                  <div className='margin-bottom margin-medium'>
                    <div className='layout458_image-wrapper'>
                      <img
                        src='assets/injection.png'
                        loading='lazy'
                        sizes='(max-width: 767px) 90vw, (max-width: 991px) 28vw, (max-width: 1439px) 27vw, 394.65625px'                        
                        alt=''
                        className='layout458_image imageglobalinputapp'
                      />
                    </div>
                  </div>
                  <div className='margin-bottom margin-xsmall'>
                    <h3 className='heading-style-h4 headingmobileoperability'>
                    Seamless Integration
                    </h3>
                  </div>
                  <p className='mobileoperabilitypar'>
                  
                  injecting and augmenting functionalities into both client applications and backend services at deployment time without requiring any changes to the code repositories, ensuring a non-intrusive and seamless integration into the deployment process. 
                  
                  
                  </p>
                </div>
                <div
                  data-w-id='a787e6ab-1c79-dbf5-a735-2f94a52b5dae'
                  className='layout458_item2'
                >
                  <div className='margin-bottom margin-medium'>
                    <div className='layout458_image-wrapper'>
                      <img
                        src='images/luke-chesser-JKUTrJ4vK00-unsplash_1.avif'
                        loading='lazy'
                        sizes='(max-width: 767px) 90vw, (max-width: 991px) 28vw, (max-width: 1439px) 27vw, 394.671875px'
                        srcSet='
                          images/luke-chesser-JKUTrJ4vK00-unsplash_1luke-chesser-JKUTrJ4vK00-unsplash.avif  500w,
                          images/luke-chesser-JKUTrJ4vK00-unsplash_1luke-chesser-JKUTrJ4vK00-unsplash.avif  800w,
                          images/luke-chesser-JKUTrJ4vK00-unsplash_1luke-chesser-JKUTrJ4vK00-unsplash.avif 1080w,
                          images/luke-chesser-JKUTrJ4vK00-unsplash_1luke-chesser-JKUTrJ4vK00-unsplash.avif 1600w,
                          images/luke-chesser-JKUTrJ4vK00-unsplash_1luke-chesser-JKUTrJ4vK00-unsplash.avif 2000w,
                          images/luke-chesser-JKUTrJ4vK00-unsplash_1luke-chesser-JKUTrJ4vK00-unsplash.avif 2600w,
                          images/luke-chesser-JKUTrJ4vK00-unsplash_1.avif                                  4810w
                        '
                        alt=''
                        className='layout458_image imageglobalinputapp'
                      />
                    </div>
                  </div>
                  <div className='margin-bottom margin-xsmall'>
                    <h3 className='heading-style-h4 headingmobileoperability'>
                     Data Collection
                    </h3>
                  </div>
                  <p className='mobileoperabilitypar'>                  
                  Generates, gathers, and presents real-time metrics, application logs including stack traces, and HTTP(S) communication logs while running on actual devices, all without requiring changes to the code repositories.
                  

                    
                  </p>
                </div>
                <div
                  data-w-id='a787e6ab-1c79-dbf5-a735-2f94a52b5db7'
                  className='layout458_item3'
                >
                  <div className='margin-bottom margin-medium'>
                    <div className='layout458_image-wrapper'>
                      <img
                        src='images/stefan-stefancik-5p_7M5MP2Iw-unsplash_1.avif'
                        loading='lazy'
                        sizes='(max-width: 767px) 90vw, (max-width: 991px) 28vw, (max-width: 1439px) 27vw, 394.65625px'
                        srcSet='
                          images/stefan-stefancik-5p_7M5MP2Iw-unsplash_1stefan-stefancik-5p_7M5MP2Iw-unsplash.avif  500w,
                          images/stefan-stefancik-5p_7M5MP2Iw-unsplash_1stefan-stefancik-5p_7M5MP2Iw-unsplash.avif  800w,
                          images/stefan-stefancik-5p_7M5MP2Iw-unsplash_1stefan-stefancik-5p_7M5MP2Iw-unsplash.avif 1080w,
                          images/stefan-stefancik-5p_7M5MP2Iw-unsplash_1.avif                                      3000w
                        '
                        alt=''
                        className='layout458_image imageglobalinputapp'
                      />
                    </div>
                  </div>
                  <div className='margin-bottom margin-xsmall'>
                    <h3 className='heading-style-h4 headingmobileoperability'>
                    Monitoring & Analysis
                    </h3>
                  </div>
                  <p className='mobileoperabilitypar'>
                   
                   Enables real-time monitoring and analysis of data collection while running on actual devices to provide insights into application performance and behavior, and to identify and diagnose issues as they occur.
                    
                  </p>
                </div>
              </div>
        
    );
}
export default InstrumentationFeature;
