import React from 'react'
import styled from 'styled-components'


const BannerTitle=styled.h2`
margin-bottom: 1rem;
  font-family: Bricolage Grotesque, sans-serif;
  font-weight: 700;
  line-height: 120%;
  margin-top: 1rem;
  font-size: 3.5rem;
   text-align: left;

`;
const BannerTextBody=styled.div`
  color: #b8b8b8;
  text-align: left;
  font-size: 1.125rem;
  line-height: 151%;
`;
const BannerContainer=styled.div`
  
  max-width: 60rem;
  
`;


function BannerSection () {
  return (
    <section className='section_layout10  hero-section' id="productBanner">
      <div className='padding-global'>
        <div className='container-large'>
          <div className='padding-section-large-2'>
            <BannerContainer>
              <BannerTitle
                data-w-id='1a860778-59bb-f860-1081-40f7f423eb07'
                className='layout484_text'
              >
                Explore Our Software Products
              </BannerTitle>
              <p
                data-w-id='1a860778-59bb-f860-1081-40f7f423eb09'
                className='text-size-medium-2 hero-paragraph-center'
              >
                <BannerTextBody>
                
                We aim to transform digital interactions across devices with our innovative software products and services to extend and enhance the capabilities of existing applications, platforms, empowering businesses to address challenges in areas like performance, security, and user satisfaction to provide their customers with a seamless and secure digital experience, 

                </BannerTextBody>
              </p>
            </BannerContainer>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BannerSection
