import React from 'react'

import BannerSection from './BannerSection'
import SolutionSection from './SolutionSection'
import ProductSection from './ProductSection'
import NavBar from '../NavBar'
import Footer from '../Footer'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'

const BackgorundImageContainer=styled.div`
     background-image: linear-gradient(70deg, #000 1%, rgba(0, 0, 0, 0.01)),
    url("../assets/earth.gif");
  background-position: right center;  // Start position
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40px;
  margin-bottom: 2rem;
  margin-left: 40px;
  margin-right: 40px;
  box-shadow: inset 0 -5px 16px 2px #000;

  // animation-name: slideBackground;
  // animation-duration: 30s;  // Duration of one complete cycle
  // animation-timing-function: linear;
  // animation-iteration-count: infinite;  // Loop forever
  // animation-direction: alternate;
  
// @keyframes slideBackground {
//   from {
//     background-position: 100% center;
//   }
//   to {
//     background-position: 0% center;
//   }
// }


`;
  




function HomePage () {
  return (
    <>
      <Helmet>
        <style>
          {`
              @media (min-width: 992px) {
      html.w-mod-js:not(.w-mod-ix) [data-w-id="3187d618-c49e-daf1-8323-85a8082dd8ec"] {
        opacity: 0;
      }

      html.w-mod-js:not(.w-mod-ix) [data-w-id="750ef52f-9867-1cee-f8f9-79438c99c1a2"] {
        opacity: 0;
      }

      html.w-mod-js:not(.w-mod-ix) [data-w-id="750ef52f-9867-1cee-f8f9-79438c99c1a4"] {
        opacity: 0;
      }

      html.w-mod-js:not(.w-mod-ix) [data-w-id="cec9584f-aa80-3e35-3644-dc4af4e62ebd"] {
        opacity: 0;
      }

      html.w-mod-js:not(.w-mod-ix) [data-w-id="750ef52f-9867-1cee-f8f9-79438c99c1a7"] {
        opacity: 0;
      }

      html.w-mod-js:not(.w-mod-ix) [data-w-id="750ef52f-9867-1cee-f8f9-79438c99c1b0"] {
        opacity: 0;
      }

      html.w-mod-js:not(.w-mod-ix) [data-w-id="750ef52f-9867-1cee-f8f9-79438c99c1b9"] {
        opacity: 0;
      }

      html.w-mod-js:not(.w-mod-ix) [data-w-id="750ef52f-9867-1cee-f8f9-79438c99c1c2"] {
        opacity: 0;
      }

      html.w-mod-js:not(.w-mod-ix) [data-w-id="f696e908-0784-fb33-e93e-b140511b8488"] {
        opacity: 0;
      }

      html.w-mod-js:not(.w-mod-ix) [data-w-id="5f9f8480-29e7-c633-2304-3c68900e636f"] {
        opacity: 0;
      }

      html.w-mod-js:not(.w-mod-ix) [data-w-id="5f9f8480-29e7-c633-2304-3c68900e637f"] {
        opacity: 0;
      }

      html.w-mod-js:not(.w-mod-ix) [data-w-id="5f9f8480-29e7-c633-2304-3c68900e6387"] {
        opacity: 0;
      }

      html.w-mod-js:not(.w-mod-ix) [data-w-id="c7f598d2-0ac8-9d50-5f79-c102be7b98b4"] {
        opacity: 0;
      }

      html.w-mod-js:not(.w-mod-ix) [data-w-id="c7f598d2-0ac8-9d50-5f79-c102be7b98bc"] {
        opacity: 0;
      }

      html.w-mod-js:not(.w-mod-ix) [data-w-id="3553fcc6-17e7-425d-b144-02a0c061aa02"] {
        opacity: 0;
      }

      html.w-mod-js:not(.w-mod-ix) [data-w-id="3553fcc6-17e7-425d-b144-02a0c061aa0a"] {
        opacity: 0;
      }

      html.w-mod-js:not(.w-mod-ix) [data-w-id="c7f598d2-0ac8-9d50-5f79-c102be7b98a8"] {
        opacity: 0;
      }
    }
          `}
        </style>
      </Helmet>
      
      <BackgorundImageContainer>
        <NavBar />
        <BannerSection />
      </BackgorundImageContainer>
      

      <SolutionSection />

      <ProductSection />
      <Footer />
    </>
  )
}
export default HomePage
