import React from 'react';


import GlobalInputAppFeaturesSection from './GlobalInputAppFeaturesSection'
import GlobalInputAppUseCasesSection from './GlobalInputAppUseCasesSection'
import GlobalInputDescription from "./GlobalInputDescription";
import GlobalInputTitle from "./GlobalInputTitle";

const GlobalInputAppSection = () =>{

    return (
        <>
        <section className='section_layout458 globalinputsection'>
            <div className='padding-global'>
                <div className='container-large'>                                            
                    <GlobalInputTitle />                                                                
                    <GlobalInputAppFeaturesSection/>
                    <GlobalInputAppUseCasesSection />                            
                    <GlobalInputDescription/>        
                </div>
                
            </div>
            
        </section>
        
        </>
    );    
}



export default GlobalInputAppSection;
