import React from 'react';
import styled from 'styled-components';
const BannerTitle=styled.h2`
margin-bottom: 1rem;
  font-family: Bricolage Grotesque, sans-serif;
  font-weight: 700;
  line-height: 120%;
  margin-top: 1rem;
  font-size: 3.5rem;
   text-align: left;
   color: #ede7de;

`;

const BannerContainer=styled.div`
  
  max-width: 80rem;

`;

function BannerSection () {
  return (
    <section className='section_layout10  hero-section' id="solutionBanner">
      <div className='padding-global'>
        <div className='container-large'>
          <div className='padding-section-large-2'>
            <BannerContainer>
            <BannerTitle>
                Discover Our Innovative Solutions
              </BannerTitle>
              <p
                data-w-id='256d28a2-8a4b-c17d-7d4c-1952d4990812'
                className='text-size-medium-2'
              >
                <span
                  data-w-id='256d28a2-8a4b-c17d-7d4c-1952d4990813'
                  className='text-span-7'
                >
                  
                  Our solutions include data protection and GDPR compliance that prioritise user control, authentication solutions that enhance both user experience and security, data encryption focused on securing encryption keys, automatic client application issue investigation that improves development processes without requiring changes to code repositories, and media asset management and streaming designed to minimise vendor lock-in for greater flexibility and cross-platform interoperability.
                  
                </span>
              </p>
            </BannerContainer>
            
          </div>
        </div>
      </div>
    </section>
  )
}
export default BannerSection
