import React from 'react';
import InstrumentationTitle from './InstrumentationTitle';
import InstrumentationDescription from './InstrumentationDescription';
import InstrumentationFeatures from './InstrumentationFeatures';

import InstrumentationUseCases from './InstrumentationUseCases';



const InstrumentationSection = () =>{

    return (
        
            <section className='section_layout458 instrumentation-systempadding'>
      
        <div className='container-large'>                      
            <InstrumentationTitle/>
            
            <InstrumentationFeatures/>
            <InstrumentationUseCases />        
            <InstrumentationDescription/>                
        </div>
      
    
    </section>
            
        
    );

}

export default InstrumentationSection;
