import React,  { useState, useEffect }  from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';


const NavContainer = styled(({ scrolled, hide, isMenuOpen, ...props }) => <div {...props} />)`
  border-bottom: 1px solid var(--relume-variable-border-color-1);
  background-color: rgba(0, 0, 0, 0.01);
  background-color: ${props => ( (props.scrolled || props.isMenuOpen)? '#000' : 'transparent')}; 
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 4.5rem;
  padding-left: 5%;
  padding-right: 5%;  
  padding-top: 3rem;
  display: flex;
  position: fixed;
  
  top: ${props => (props.hide ? '-100px' : '0')}; // Hide or show based on scroll
  
  transition: top 0.3s ease-in-out; // Smooth transition for sliding effect
  left: 0;
  right: 0;
  z-index: 10;      
    @media  (max-width: 991px) {
      height: ${props => (props.isMenuOpen ? '4000px' : 'auto')};                
   }

`  
const NavInnerContainer=styled.div`
grid-column-gap: 16px;
  grid-row-gap: 16px;  
  color: #ede7de;
  grid-template-rows: auto;
  grid-template-columns: 0.375fr 1fr 0.375fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;  
  margin-left: auto;
  margin-right: auto;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  display: grid;
   @media  (max-width: 992px) {
    display: flex;
    align-items: start;
    
  }

  

`;
const CompanyName=styled.div`
display: flex;  
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  min-width: 10rem;
  font-size: 1rem;
  font-weight: 700;
  color: white;
  font-family: 'Poppins', sans-serif;
  
  `;

  const CompanyNameAndLogo=styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
 `; 

const Nav=styled(({ scrolled, hide, isMenuOpen, ...props }) => <nav {...props} />)`
  justify-content: center;
  align-items: center;
  display: flex;
  position: static;
  
  
  @media  (max-width: 991px) {
     position: absolute;
     top:60px;
     right:150px;
     
    border-bottom: 1px solid var(--\<unknown\|relume-variable-border-color-1\>);    
    -webkit-text-fill-color: inherit;
    background-color: rgba(0, 0, 0, 0.88);
    background-clip: border-box;
    justify-content: center;
    
    padding: 1rem 5% 2.5rem;
    display: flex;
    
    overflow: auto;
    flex-direction: column;
    grid-template-columns: 0.25fr 1fr;
    display:${props => (props.isMenuOpen ? 'flex' : 'none')};
    
    align-items: center;
    font-size: 1.125rem;
    
    
}
`;

const MobileMenuButtonContainer=styled(({ scrolled, hide, isMenuOpen, ...props }) => <div {...props} />)`
    padding: 0;
    position: relative;
    float: right;
    padding: 18px;
    font-size: 24px;
    display: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;    
    @media  (min-width: 992px) {
      display:none;
    }
`;



const ContactUsButton=styled(({ scrolled, hide, isMenuOpen, ...props }) => <div {...props} />)`
  grid-column-gap: 1rem;
  margin-left: 1rem;
  display: inline;
  width:150px;
  @media  (max-width: 992px) {
    display:none;
  }
`;

const ContactUsMenu=styled(({ scrolled, hide, isMenuOpen, ...props }) => <NavLink {...props} />)`
  color: #ede7de;
  padding: 0.5rem 1rem;
  text-align: center;
  padding: 0.75rem 0;
  font-size: 1.125rem;
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: white;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;

  @media  (min-width: 992px) {
    display:none;
  }
`;


// Styled components for each part of the menu icon

const MobileMenuButtonIcon = styled.div`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30px;
  height: 20px;
  display: flex;
  cursor: pointer;
  
`;


const Line = styled(({ scrolled, hide, isMenuOpen, ...props }) => <NavLink {...props} />)`
  background-color: #fff;
  width: 30px;
  height: 2px;
  transition: transform 0.3s ease, opacity 0.3s ease;

  /* Position and transformation for "X" icon */
  ${({ isMenuOpen }) => isMenuOpen ? `
    position: absolute;
    &:nth-child(1) {
      transform: rotate(45deg);
    }
    &:nth-child(2) {
      transform: rotate(-45deg);
    }
    &:nth-child(3) {
      display: none; /* Hide the middle bar when menu is open */
    }
  ` : ''}
`;

function MobileMenuButton({ isMenuOpen,toggleMenu}) {
  return (
    <MobileMenuButtonContainer isMenuOpen={isMenuOpen}>          
      <MobileMenuButtonIcon onClick={toggleMenu}>
        <Line isMenuOpen={isMenuOpen} />
        <Line isMenuOpen={isMenuOpen} /> 
        <Line isMenuOpen={isMenuOpen} />
    </MobileMenuButtonIcon>
    </MobileMenuButtonContainer>
  );
}

const initialScrollData={
    lastScrollY: 0,
    direction: 2,  
    counter: 0  
}
function NavBar() {
  // Define the base class and the active class
  const baseClass = 'navbar2_link w-nav-link';
  const activeClass = 'w--current'; // Active class as used in your original CSS
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollData, setScrollData] = useState(initialScrollData);
  const [hideNav, setHideNav] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if(isMenuOpen){
        setIsMenuOpen(false);
      }
      setIsScrolled(currentScrollY > 120);
      if(scrollData.direction ===2){
        setScrollData({lastScrollY: currentScrollY, direction: 0, counter: 0});
      }
      else if(scrollData.direction ===0){
        if(currentScrollY > scrollData.lastScrollY){
          setScrollData({lastScrollY: currentScrollY, direction: 1, counter: 0});
        }
        else if(currentScrollY < scrollData.lastScrollY){
          setScrollData({lastScrollY: currentScrollY, direction: -1, counter: 0});
        }
      }
      else if(scrollData.direction ===1){
        if(currentScrollY > scrollData.lastScrollY){
          setScrollData({lastScrollY: currentScrollY, counter: scrollData.counter+1, direction: 1});
        }
        else if(currentScrollY < scrollData.lastScrollY){
          setScrollData({lastScrollY: currentScrollY, counter:0, direction: -1});
        }
        
      }
      else if(scrollData.direction ===-1){
        if(currentScrollY > scrollData.lastScrollY){
          setScrollData({lastScrollY: currentScrollY, counter: 0, direction: 1});
        }
        else if(currentScrollY < scrollData.lastScrollY){
          setScrollData({lastScrollY: currentScrollY, counter: scrollData.counter+1, direction: -1});
        }
      }
      
      if((!hideNav) && scrollData.direction===1 && scrollData.counter > 20){          
        setHideNav(true);
        setScrollData(initialScrollData);        
      }
      else if(hideNav && scrollData.direction===-1 && scrollData.counter >1){
         setHideNav(false);
         setScrollData(initialScrollData);         
      }
      

      // if (currentScrollY > lastScrollY) { // If current scroll is greater, user is scrolling down
      //   setHideNav(true);
      // } else if (currentScrollY < lastScrollY) { // If current scroll is less, user is scrolling up
      //   setHideNav(false);
      // }
      // setLastScrollY(currentScrollY); // Update lastScrollY to current Y
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hideNav, scrollData]);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    return false;
  };
  const closeMenu = () => {
    if(isMenuOpen){
      setIsMenuOpen(false);
    }
  };

  return (
    <NavContainer scrolled={isScrolled} hide={hideNav} isMenuOpen={isMenuOpen} onClick={closeMenu}>
      <NavInnerContainer isMenuOpen={isMenuOpen}>
      
        <NavLink to='/' className='navbar2_logo-link-2 w-nav-brand'>        
        <CompanyNameAndLogo>
        <img
            src='images/logo-2-1.svg'
            loading='lazy'
            alt=''
            className='navbar2_logo'
          />
          <CompanyName style={{ color: 'white' }}>Iterative Solution</CompanyName>

        </CompanyNameAndLogo>
          
          
        </NavLink>
        
        
        <Nav
          role='navigation'
          id='w-node-_978a63ae-96d3-062b-357c-918e23c53267-23c53263'          
          isMenuOpen={isMenuOpen}
        >
          <NavLink
            to='/#homeBanner'
            className={({ isActive }) => isActive ? `${baseClass} ${activeClass}` : baseClass}
          >
            Home
          </NavLink>
          <NavLink
            to='/solutions#solutionBanner'
            className={({ isActive }) => isActive ? `${baseClass} ${activeClass}` : baseClass}
          >
            Solutions
          </NavLink>
          <NavLink
            to='/products#productBanner'
            className={({ isActive }) => isActive ? `${baseClass} ${activeClass}` : baseClass}
          >
            Products
          </NavLink>
          <ContactUsMenu to='/contact-us#contactTop' isMenuOpen={isMenuOpen}>Contact us</ContactUsMenu>
        </Nav>
            
            <ContactUsButton to='/contact-us#contactTop'>          
                 <NavLink to='/contact-us' className="link-block w-inline-block w--current">
               
                  <div className='text-block-2'>Contact us</div>
                  <img
                    src='images/Vector.svg'
                    loading='lazy'
                    alt=''
                    className='image-3'                                      
                  />          
                  </NavLink>  
            </ContactUsButton>
            
    
            <MobileMenuButton isMenuOpen={isMenuOpen} toggleMenu={toggleMenu}/>
          
        
        </NavInnerContainer>
      </NavContainer>
  );
}

export default NavBar;