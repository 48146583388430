import React from 'react'

function PrivacySection () {
  return (
    <section
      data-w-id='3187d618-c49e-daf1-8323-85a8082dd8ec'
      className='privacy-policy-section'
    >
      <div className='padding-global'>
        <div className='container-large'>
          <div className='padding-section-large-2 dataprotectionpadding'>
            <div
              data-w-id='3187d618-c49e-daf1-8323-85a8082dd8f0'
              className='w-layout-grid layout10_component-2 privacy-policy'
            >
              <div className='layout10_content'>
                <div
                  data-w-id='3187d618-c49e-daf1-8323-85a8082dd8f5'
                  className='margin-bottom margin-small margin-left'
                >
                  <h1 className='heading-4'>
                    <span
                      data-w-id='0a43f181-30b3-3422-fc58-66d532a02e55'
                      className='text-span-4 data-protection-headings'
                    >
                      Privacy Policy
                    </span>
                  </h1>
                </div>
                <div className='margin-bottom margin-medium'>
                  <p
                    data-w-id='3187d618-c49e-daf1-8323-85a8082dd8f9'
                    className='text-size-medium-2 dataprotectionparagraph'
                  >
                    <span className='text-span-7 dataprotectionparag'>
                    We do not track your activities and we do not store your data. We don't even have a server database or any form of cloud storage which would allow us to.
                    </span>
                  </p>

                  <p
                    data-w-id='3187d618-c49e-daf1-8323-85a8082dd8f9'
                    className='text-size-medium-2 dataprotectionparagraph'
                  >
                    <span className='text-span-7 dataprotectionparag'>
                    Our privacy policy applies to our website and all of our Global Input App software components, including the mobile app, its extensions and library components.
                    </span>
                  </p>

                  <p
                    data-w-id='3187d618-c49e-daf1-8323-85a8082dd8f9'
                    className='text-size-medium-2 dataprotectionparagraph'
                  >
                    <span className='text-span-7 dataprotectionparag'>
                    Applications that use our software components allow you to take complete control over your sensitive information, by using your device's storage to store either the data itself or the master encryption key used when encrypting your data.

                    </span>
                  </p>

                  <p
                    data-w-id='3187d618-c49e-daf1-8323-85a8082dd8f9'
                    className='text-size-medium-2 dataprotectionparagraph'
                  >
                    <span className='text-span-7 dataprotectionparag'>
                    A connected application can always request data on-demand via the mobile app, which will ask you for confirmation to push the requested data over to the application. The communication between your devices is secured using end-to-end encryption and the encryption key that starts the session is dynamically generated within your device for each session and can only be accessed via an encrypted QR Code.

                    </span>
                  </p>
                 

                  <p
                    data-w-id='3187d618-c49e-daf1-8323-85a8082dd8f9'
                    className='text-size-medium-2 dataprotectionparagraph'
                  >
                    <span className='text-span-7 dataprotectionparag'>
                    Your data in your mobile app always stays encrypted and is decrypted only at the point of usage. The keys used in the encryption never leave your mobile device and are encrypted with your memorable password. This means that you are responsible for backing up your encryption keys in your mobile app and the data that is encrypted with the encryption keys.

                    </span>
                  </p>

                  <p
                    data-w-id='3187d618-c49e-daf1-8323-85a8082dd8f9'
                    className='text-size-medium-2 dataprotectionparagraph'
                  >
                    <span className='text-span-7 dataprotectionparag'>
                    Since you can export an encryption key as an encrypted QR code, and import it simply by scanning it, the process of backing up the keys and sharing between your devices is straightforward.



                    </span>
                  </p>
                 
                  
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PrivacySection
