import React from 'react'

function SumulatorUseCasesSection () {
  return (
    <section className='section_layout305 usecase2padding'>
      
        <div className='container-large'>
          
            <div id='learn-more-case-study-2' className='layout305_component'>
              <div className='w-layout-grid layout305_list secondusecase'>
                <div
                  id='w-node-ce2c218d-c968-ae82-0f46-c04cea64a68d-08f35c86'
                  data-w-id='ce2c218d-c968-ae82-0f46-c04cea64a68d'
                  className='layout305_item'
                >
                  <div className='layout305_item-icon-wrapper'>
                    <img
                      src='images/usecases1.svg'
                      loading='lazy'
                      alt=''
                      className='icon-1x1-medium'
                    />
                  </div>
                  <div className='layout305_item-text-wrapper'>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-style-h5-2 usecasesheading'>
                      Testing with Desktop Browser
                      </h3>
                    </div>
                    <p className='usecaseparag'>
                    Utilise desktop browsers to test applications designed for video streaming devices, reducing the dependency on physical device access.
                    </p>
                  </div>
                </div>
                <div
                  id='w-node-ce2c218d-c968-ae82-0f46-c04cea64a696-08f35c86'
                  data-w-id='ce2c218d-c968-ae82-0f46-c04cea64a696'
                  className='layout305_item'
                >
                  <div className='layout305_item-icon-wrapper'>
                    <img
                      src='images/usecases4.svg'
                      loading='lazy'
                      alt=''
                      className='icon-1x1-medium'
                    />
                  </div>
                  <div className='layout305_item-text-wrapper'>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-style-h5-2 usecasesheading'>
                      Test Automation
                      </h3>
                    </div>
                    <p className='usecaseparag'>
                    Simulating multiple device environments on Chrome browser to perform comprehensive testing and utilize test automation tools designed for web applications.
                    </p>
                  </div>
                </div>
                <div
                  id='w-node-ce2c218d-c968-ae82-0f46-c04cea64a69f-08f35c86'
                  data-w-id='ce2c218d-c968-ae82-0f46-c04cea64a69f'
                  className='layout305_item'
                >
                  <div className='layout305_item-icon-wrapper'>
                    <img
                      src='images/usecases3.svg'
                      loading='lazy'
                      alt=''
                      className='icon-1x1-medium'
                    />
                  </div>
                  <div className='layout305_item-text-wrapper'>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-style-h5-2 usecasesheading'>
                        Continuous Integration
                      </h3>
                    </div>
                    <p className='usecaseparag'>
                      Integrate the Device Simulator into continuous integration
                      workflows to automate testing processes for different
                      device environments, enhancing development efficiency and
                      product quality.
                    </p>
                  </div>
                </div>
              </div>
            </div>

        </div>

    </section>
  )
}
export default SumulatorUseCasesSection
