import React from 'react'

function DataProtectionSection () {
  return (
    <section
      data-w-id='3187d618-c49e-daf1-8323-85a8082dd8ec'
      className='section_layout10'
    >
      <div className='padding-global'>
        <div className='container-large'>
          <div className='padding-section-large-2 dataprotectionpadding'>
            <div
              data-w-id='3187d618-c49e-daf1-8323-85a8082dd8f0'
              className='w-layout-grid layout10_component-2 dataprotectionbackround'
            >
              <div className='layout10_content'>
                <div
                  data-w-id='3187d618-c49e-daf1-8323-85a8082dd8f5'
                  className='margin-bottom margin-small margin-left'
                >
                  <h1 className='heading-4'>
                    <span
                      data-w-id='0a43f181-30b3-3422-fc58-66d532a02e55'
                      className='text-span-4 data-protection-headings'
                    >
                      Data Protection and GDPR Compliance.
                    </span>
                  </h1>
                </div>
                <div className='margin-bottom margin-medium'>
                  <p
                    data-w-id='3187d618-c49e-daf1-8323-85a8082dd8f9'
                    className='text-size-medium-2 dataprotectionparagraph'
                  >
                    <span className='text-span-7 dataprotectionparag'>
                      Our GDPR compliance solution addresses the complexities and costs associated with data privacy regulations by maximizing data security, enhancing user control over personal information, and increasing personal accountability for data usage.
                    </span>
                  </p>
                  <div
                    data-w-id='eec97c17-e7be-8c62-ea2e-8f1f731ee817'
                    className='div-block-3 data-protection-padding w-clearfix'
                  >
                    <img
                      src='images/Polygon-2.svg'
                      loading='lazy'
                      alt=''
                      className='image-6'
                    />
                    <div className='text-block-5'>
                      <span className='text-span-8'>
                        Enhanced User Control:{' '}
                      </span>
                      Empowering users with complete control over their data, allowing them to push or unlock their information only at the point of use, minimizing the risk of data abuse and unauthorized access.
                    </div>
                  </div>
                  <div
                    data-w-id='c8179426-974b-ed22-3dc7-428d5ed0e143'
                    className='div-block-3 data-protection-padding w-clearfix'
                  >
                    <img
                      src='images/Polygon-2.svg'
                      loading='lazy'
                      alt=''
                      className='image-6'
                    />
                    <div className='text-block-5'>
                      <span className='text-span-8'>
                        Encrypted Data Storage:{' '}
                      </span>
                      <span>
                      Maximizing the security of user data by leveraging encrypted mobile storage to secure encryption keys and the decryption process, minimizing risks associated with external storage and databases, and ensuring that personal information remains protected and accessible only to authorized individuals.
                      </span>
                    </div>
                  </div>
                  <div
                    data-w-id='50598936-ceb6-c1c5-76b1-65b6aa3303bb'
                    className='div-block-3 data-protection-padding w-clearfix'
                  >
                    <img
                      src='images/Polygon-2.svg'
                      loading='lazy'
                      alt=''
                      className='image-6'
                    />
                    <div className='text-block-5'>
                      <span className='text-span-8'>
                        Compliance and Accountability:{' '}
                      </span>
                      <span>
                        Enhancing personal accountability in data usage by implementing robust mechanisms in data decryption processes, ensuring access only by authorized individuals, and enabling users to track and monitor data usage, thereby ensuring compliance with GDPR and other data privacy regulations.                        
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DataProtectionSection
