import React from "react";


function DeviceSimulatorDescription(){
   return (
    <div className='container-large'>                                                                
        
        <div className='button-group simulatorButtonContainer'>
          <a
            data-w-id='dce1eda5-1845-ce6a-79e6-0ef264bcf013'
            href='https://chromewebstore.google.com/detail/device-simulator/bpkidjmpeedifhohncickkelnljcjgld'
            className='link-block-2 w-inline-block'
          >
            <div className='text-block-4'>Install the Chrome Extension</div>
            <img src='images/Frame-47576.svg' loading='lazy' alt='' />
          </a>                    
        </div>
      
          </div>
          
   );

}
export default DeviceSimulatorDescription;