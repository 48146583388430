import React from 'react'
function MediaAssetManagerUseCases () {
  return (
    <section className='section_layout305 usecase4padding'>
      <div className='padding-global'>
        <div className='container-large'>
          <div className='padding-section-large-2 usecase4padding'>
            <div id='learn-more-case-study-4' className='layout305_component'>
              
              <div className='w-layout-grid layout305_list secondusecase'>
                <div
                  id='w-node-_6cd6ee58-c9e9-ffdf-06f2-c1cdc1851fca-08f35c86'
                  data-w-id='6cd6ee58-c9e9-ffdf-06f2-c1cdc1851fca'
                  className='layout305_item'
                >
                  <div className='layout305_item-icon-wrapper'>
                    <img
                      src='images/1broadcasting.svg'
                      loading='lazy'
                      alt=''
                      className='icon-1x1-medium'
                    />
                  </div>
                  <div className='layout305_item-text-wrapper'>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-style-h5-2 usecasesheading'>
                      Cross-Platform Media Management
                      </h3>
                    </div>
                    <p className='usecaseparag'>
                    Flexible media assets management across platforms,  reducing the risk of vendor lock-in and enhancing operational efficiency.

                    </p>
                  </div>
                </div>
                <div
                  id='w-node-_6cd6ee58-c9e9-ffdf-06f2-c1cdc1851fd3-08f35c86'
                  data-w-id='6cd6ee58-c9e9-ffdf-06f2-c1cdc1851fd3'
                  className='layout305_item'
                >
                  <div className='layout305_item-icon-wrapper'>
                    <img
                      src='images/2contentcreator.svg'
                      loading='lazy'
                      alt=''
                      className='icon-1x1-medium'
                    />
                  </div>
                  <div className='layout305_item-text-wrapper'>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-style-h5-2 usecasesheading'>
                        Content Integration
                      </h3>
                    </div>
                    <p className='usecaseparag'>
                      Integrating contents across multiple sources with different metadata formats and standards, ensuring seamless content delivery.
                    </p>
                  </div>
                </div>
                <div
                  id='w-node-_6cd6ee58-c9e9-ffdf-06f2-c1cdc1851fdc-08f35c86'
                  data-w-id='6cd6ee58-c9e9-ffdf-06f2-c1cdc1851fdc'
                  className='layout305_item'
                >
                  <div className='layout305_item-icon-wrapper'>
                    <img
                      src='images/3mediaplatforms.svg'
                      loading='lazy'
                      alt=''
                      className='icon-1x1-medium'
                    />
                  </div>
                  <div className='layout305_item-text-wrapper'>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-style-h5-2 usecasesheading'>
                        End-to-End Media Solutions
                      </h3>
                    </div>
                    <p className='usecaseparag'>
                      Comprehensive media solutions, from content ingestion from various sources, transocding and transforming, menagement, quality control, schedulring to distribution, ensuring a seamless media workflow.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MediaAssetManagerUseCases
