import React from "react";

function GlobalInputTitle() {
    return (
      <div className='margin-bottom margin-xxlarge'>
                                <div className='w-layout-grid layout458_content'>      
    <div
        data-w-id='dae45b6c-b14a-0233-c200-d69247794930'
        className='layout458_content-left'
      >
        <h2 className='heading-4 globalinputh globalinputheading'>
          Global Input App
        </h2>
      </div>
      </div>
      </div>
      );

}
export default GlobalInputTitle;

