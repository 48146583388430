import React from 'react'
import { Link } from 'react-router-dom';
function DataSecuritySection () {
  return (
    <section className='section_layout10'>
      <div className='padding-global'>
        <div className='container-large'>
          <div className='padding-section-large-2 datasecuritypadd'>
            <div className='w-layout-grid layout10_component-2'>
              <div className='layout10_content'>
                <div
                  data-w-id='5f9f8480-29e7-c633-2304-3c68900e636d'
                  className='margin-bottom margin-small margin-left'
                >
                  <h1 className='heading-4'>
                    <span
                      data-w-id='5f9f8480-29e7-c633-2304-3c68900e636f'
                      className='text-span-4'
                    >
                      Data security
                    </span>
                  </h1>
                </div>
                <div
                  data-w-id='5f9f8480-29e7-c633-2304-3c68900e637d'
                  className='margin-bottom margin-medium datasecurityparag'
                >
                  <p className='text-size-medium-2 datasecurityparag'>
                    <span
                      data-w-id='5f9f8480-29e7-c633-2304-3c68900e637f'
                      className='text-span-7 datasecurityspan'
                    >
                      
                      Our data security solutions maximize the protection of sensitive information by leveraging mobile devices’ encrypted data storage to manage encryption keys and secure data through a robust, multi-layered, and hierarchical encryption strategy. This ensures encryption keys remain protected and accessible only to authorized users,  making it secure and intuitive for users to encrypt and decrypt data directly on their devices.                      
                    </span>
                  </p>
                </div>
                
              </div>
            </div>
            <div
              data-w-id='c7f598d2-0ac8-9d50-5f79-c102be7b98a8'
              className='w-layout-grid layout10_component-5'
            >
              
                <div className='w-layout-grid layout10_item-list-5'>
                  <div
                    data-w-id='c7f598d2-0ac8-9d50-5f79-c102be7b98b4'
                    className='layout10_item'
                  >
                    <div className='margin-bottom margin-xsmall'>
                      <img src='images/235.svg' loading='lazy' alt='' />
                      <img
                        src='images/icon.svg'
                        loading='lazy'
                        alt=''
                        className='icon-1x1-medium'
                      />
                    </div>
                    <div className='margin-bottom margin-xsmall'>
                      <h6 className='heading-global-input-app'>
                        Decentralised Encryption Key Management
                      </h6>
                    </div>
                    <p className='paragraphglobalinputapp'>
                    Designed to Reduce the risk of key exposure and unauthorized access by securely storing encryption keys directly on user-controlled devices.
                    </p>
                  </div>
                  <div
                    data-w-id='c7f598d2-0ac8-9d50-5f79-c102be7b98bc'
                    className='layout10_item'
                  >
                    <div className='margin-bottom margin-xsmall'>
                      <img
                        src='images/mobile-phone.svg'
                        loading='lazy'
                        alt=''
                      />
                      <img
                        src='images/icon.svg'
                        loading='lazy'
                        alt=''
                        className='icon-1x1-medium'
                      />
                    </div>
                    <div className='margin-bottom margin-xsmall'>
                      <h6 className='heading-global-input-app'>
                        Mobile Encryption System
                      </h6>
                    </div>
                    <p className='paragraphglobalinputapp'>
                      Designed to increase the data security and enhancing the personal accountability of the description process for business critical applications and database systems.
                    </p>
                  </div>
                </div>
                <div className='w-layout-grid layout10_item-list-5'>
                  <div
                    data-w-id='3553fcc6-17e7-425d-b144-02a0c061aa02'
                    className='layout10_item'
                  >
                    <div className='margin-bottom margin-xsmall'>
                      <img src='images/2323.svg' loading='lazy' alt='' />
                      <img
                        src='images/icon.svg'
                        loading='lazy'
                        alt=''
                        className='icon-1x1-medium'
                      />
                    </div>
                    <div className='margin-bottom margin-xsmall'>
                      <h6 className='heading-global-input-app'>
                        End-to-End Data Security
                      </h6>
                    </div>
                    <p className='paragraphglobalinputapp'>                      
                      Sensitive information remains secure from the point of origin to the point of use or consumption, ensuring data is protected throughout its lifecycle.
                    </p>
                  </div>
                  <div
                    data-w-id='3553fcc6-17e7-425d-b144-02a0c061aa0a'
                    className='layout10_item'
                  >
                    <div className='margin-bottom margin-xsmall'>
                      <img
                        src='images/encryption-1.svg'
                        loading='lazy'
                        alt=''
                      />
                      <img
                        src='images/icon.svg'
                        loading='lazy'
                        alt=''
                        className='icon-1x1-medium'
                      />
                    </div>
                    <div className='margin-bottom margin-xsmall'>
                      <h6 className='heading-global-input-app'>
                        Hierarchical Encryption
                      </h6>
                    </div>
                    <p className='paragraphglobalinputapp'>
                      Designed to protect sensitive data by encrypting it with multiple keys, ensuring that the data is secure and accessible only to authorized users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

    </section>
  )
}

export default DataSecuritySection
