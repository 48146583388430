

async function  sendMessages (data) {
    const { firstName, lastName, email, phoneNumber, message } = data
    return fetch(
        'https://c4.iterativesolution.co.uk/api/messages/send-messages/message.json',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            phoneNumber,
            message
          })
        }
      )
}
const api = { sendMessages }
export default api;