import React from 'react'
function BannerSection () {
  return (
    <section id="homeBanner"
      data-w-id='3187d618-c49e-daf1-8323-85a8082dd8ec'
      className='section_layout10  hero-section'
    >
      <div className='padding-global'>
        <div className='container-large'>
          <div className='padding-section-large-2'>
            <div
              data-w-id='3187d618-c49e-daf1-8323-85a8082dd8f0'
              className='w-layout-grid layout10_component-2'
            >
              <div className='layout10_content'>
                <div
                  data-w-id='3187d618-c49e-daf1-8323-85a8082dd8f5'
                  className='margin-bottom margin-small'
                >
                  <h1 className='heading-4 innovatingheading'>
                    <span
                      data-w-id='0a43f181-30b3-3422-fc58-66d532a02e55'
                      className='text-span-4'
                    >
                      Shaping the{' '}
                    </span>
                    
                    <span className='text-span-4'>Future{' '}</span>
                    <span className='text-span-5'>with{' '}</span>
                    <span className='text-span'>Secure{' '}</span>
                    <span className='text-span-6'>and{' '}</span>

                    <span className='text-span'>Adaptable </span>
                    <span className='text-span-5'>Software </span>
                    <span className='text-span'>Innovations</span>
                  </h1>
                </div>
                <div className='margin-bottom margin-medium'>
                  <p
                    data-w-id='3187d618-c49e-daf1-8323-85a8082dd8f9'
                    className='text-size-medium-2 innovatingparag'
                  >
                    <span className='text-span-7'>
                      We are a pioneering software company dedicated to
                      delivering innovative and secure software solutions across
                      deiverse domains, including secure mobile
                      integrations, cloud infrastructure,  IoT integration, media assets management and end-to-end media streaming solutions.
                    </span>
                  </p>
                </div>
                {/* 
                <div className='margin-top margin-medium'>
                  <div className='button-group'>
                    <a
                      href='#learn-more'
                      className='link-block-2 w-inline-block'
                    >
                      <div className='text-block-4'>Learn more</div>
                      <img src='images/Frame-47576.svg' loading='lazy' alt='' />
                    </a>
                    <a
                      href='contact-us.html'
                      className='link-block-2 link-blok-3 w-inline-block'
                    >
                      <div className='text-block-4'>Contact us</div>
                      <img src='images/Vector.svg' loading='lazy' alt='' />
                    </a>
                  </div>                  
                </div>
                */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default BannerSection
