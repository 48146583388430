import React from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import NavBar from '../NavBar'
import Footer from '../Footer'

import BannerSection from './BannerSection'
import DataProtectionSection from './DataProtectionSection'
import MobileAuthenticationSection from './MobileAuthenticationSection'
import IssueInvestigationSection from './IssueInvestigationSection'
import DataSecuritySection from './DataSecuritySection'
import MediaStreamingSection from './MediaStreamingSection'

const BackgroundImageContainer=styled.div`
     background-image: linear-gradient(70deg, #000 1%, rgba(0, 0, 0, 0.01)),
    url("../assets/earth.gif");
  background-position: right center;  // Start position
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40px;
  margin-bottom: 2rem;
  margin-left: 40px;
  margin-right: 40px;
  box-shadow: inset 0 -5px 16px 2px #000;
  border: 1px solid #000;

  


`;

const ProductButtonContaeiner=styled.div`
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
padding-right: 10%;


`;
function SolutionPage () {
  return (
    <>
      <Helmet>
        <style>
          {`
          @media (min-width: 992px) {
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="e39683da-6faa-fb25-807e-1bb9b1352a06"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="256d28a2-8a4b-c17d-7d4c-1952d4990813"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="0a43f181-30b3-3422-fc58-66d532a02e55"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="3187d618-c49e-daf1-8323-85a8082dd8f9"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="eec97c17-e7be-8c62-ea2e-8f1f731ee817"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="c8179426-974b-ed22-3dc7-428d5ed0e143"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="50598936-ceb6-c1c5-76b1-65b6aa3303bb"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="3187d618-c49e-daf1-8323-85a8082dd8f5"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="3187d618-c49e-daf1-8323-85a8082dd8f0"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="256d28a2-8a4b-c17d-7d4c-1952d4990812"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="750ef52f-9867-1cee-f8f9-79438c99c1a1"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="750ef52f-9867-1cee-f8f9-79438c99c1a4"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="750ef52f-9867-1cee-f8f9-79438c99c1a7"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="750ef52f-9867-1cee-f8f9-79438c99c1b0"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="750ef52f-9867-1cee-f8f9-79438c99c1b9"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="750ef52f-9867-1cee-f8f9-79438c99c1c2"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="02ac780a-fcd9-b5c3-654d-530b14bf73a3"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="3c6ffe04-1f7e-6f29-5152-c8bafd3a2f83"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="105d4ccd-b679-1092-1c8d-2348a27e15df"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="de4beb55-033f-242c-b9cd-b0be2a483141"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="02ac780a-fcd9-b5c3-654d-530b14bf73a8"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="02ac780a-fcd9-b5c3-654d-530b14bf73a1"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="5f9f8480-29e7-c633-2304-3c68900e636d"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="5f9f8480-29e7-c633-2304-3c68900e637d"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="5f9f8480-29e7-c633-2304-3c68900e6387"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="c7f598d2-0ac8-9d50-5f79-c102be7b98b4"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="c7f598d2-0ac8-9d50-5f79-c102be7b98bc"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="3553fcc6-17e7-425d-b144-02a0c061aa02"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="3553fcc6-17e7-425d-b144-02a0c061aa0a"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="49d3e862-78d9-11dd-54b1-461ca422bb38"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="49d3e862-78d9-11dd-54b1-461ca422bb3b"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="49d3e862-78d9-11dd-54b1-461ca422bb3e"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="49d3e862-78d9-11dd-54b1-461ca422bb47"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="49d3e862-78d9-11dd-54b1-461ca422bb50"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="49d3e862-78d9-11dd-54b1-461ca422bb59"] {
          opacity: 0;
        }
      }
           
          `}
        </style>
      </Helmet>
      <BackgroundImageContainer>
      <NavBar />
      <BannerSection />
      </BackgroundImageContainer>

      <DataProtectionSection />
      <MobileAuthenticationSection />
      <IssueInvestigationSection />
      <DataSecuritySection />
      <MediaStreamingSection />
      <ProductButtonContaeiner>
      <div className='margin-top margin-medium'>
                  <div className='button-group'>
                    <a
                      href='/products#productBanner'
                      className='link-block-2 w-inline-block'
                    >
                      <div className='text-block-4'>Visit Product Page</div>
                      <img src='images/Frame-47576.svg' loading='lazy' alt='' />
                    </a>
                    
                  </div>                  
                </div>
      </ProductButtonContaeiner>

      <Footer />
    </>
  )
}
export default SolutionPage
