import React from 'react'
function ContactUsCard () {
  return (
    <div className='contact-us-main-div-wrapper'>                
    <div className='margin-bottom margin-small'>
                  
                </div>
    <p
                  data-w-id='ca9d13e0-fb92-c3d9-f1b9-307646019edb'
                  className='text-size-medium-7 training-hub-paragraph contact-us-main-paragraph contact-us-second-paragraph'
                >
                 
                </p>
    <div className='contact-us-additional-info'>
      <div className='contact14_content'>
        <div className='contact14_contact-list'>
          <div className='contact14_item'>
            
              <div
                data-w-id='cff33a5a-f95c-2503-99da-13922a51cf3b'
                className='contact14_icon-wrapper w-clearfix'
              >
                <img
                  src='images/Content-3.svg'
                  loading='lazy'
                  alt=''
                  className='image-7'
                />
                <div className='contact-wrapper w-clearfix'>
                  <h4
                    id='w-node-cff33a5a-f95c-2503-99da-13922a51cf3d-582da552'
                    className='heading-6 image-7 heading-contact'
                  >
                    Email
                  </h4>
                  <div
                    id='w-node-cff33a5a-f95c-2503-99da-13922a51cf3f-582da552'
                    className='text-block-6 contactustext'
                  >                   
                    <p>info@iterativesolution.co.uk</p>
                  </div>
                </div>
              </div>
              <div
                data-w-id='edbb04f1-f4a0-2727-f84c-2dcaa1dfe419'
                className='contact14_icon-wrapper w-clearfix'
              >
                <img
                  src='images/Content-4.svg'
                  loading='lazy'
                  alt=''
                  className='conimg2'
                />
                <div className='contact-wrapper w-clearfix'>
                  <h4
                    id='w-node-edbb04f1-f4a0-2727-f84c-2dcaa1dfe41c-582da552'
                    className='heading-6 image-7 heading-contact'
                  >
                    Phone
                  </h4>
                  <div
                    id='w-node-edbb04f1-f4a0-2727-f84c-2dcaa1dfe41e-582da552'
                    className='text-block-6 contactustext'
                  >                  
                    <p>+44 (0) 20 3290 6278</p>
                  </div>
                </div>
              </div>
              <div
                data-w-id='a0d7f4d4-5097-239f-f090-afeb024a8fdc'
                className='contact14_icon-wrapper w-clearfix'
              >
                <img
                  src='images/Content-5.svg'
                  loading='lazy'
                  alt=''
                  className='image-8'
                />
                <div className='contact-wrapper w-clearfix'>
                  <h4
                    id='w-node-a0d7f4d4-5097-239f-f090-afeb024a8fdf-582da552'
                    className='heading-6 image-7 heading-contact'
                  >
                    Address
                  </h4>
                  <div
                    id='w-node-a0d7f4d4-5097-239f-f090-afeb024a8fe1-582da552'
                    className='text-block-6 contactustext'
                  >
                    <p>Iterative Solution Limited</p>
                    <p>Kemp House</p>
                    <p>124 City Road</p>
                    <p>London EC1V 2NX</p>
                  </div>
                </div>
              </div>
            
          </div>
        </div>
      </div>
    </div>

    </div>
  )
}

export default ContactUsCard
