import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import NavBar from '../NavBar'
import Footer from '../Footer'

import BannerSection from './BannerSection'
import GlobalInputAppSection from './global-input-app';
import InstrumentationSection from './instrumention';
import DeviceSimulatorSection from './device-simulator'
import MediaAssetManagerSection from './media-asset-manager'

const BackgorundImageContainer=styled.div`
     background-image: linear-gradient(70deg, #000 50%, rgba(0, 0, 0, 0.01)),
    url("../assets/home-products-background-3.png");
  background-position: right  top;  // Start position
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40px;
  margin-bottom: 2rem;
  margin-left: 40px;
  margin-right: 40px;
  box-shadow: inset 0 -5px 16px 2px #000;
  min-height: 800px;  
  


`;
  

function ProductsPage () {
  return (
    <>
      <Helmet>
        <style>
          {`
                @media (min-width: 992px) {
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="1a860778-59bb-f860-1081-40f7f423eb07"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="1a860778-59bb-f860-1081-40f7f423eb09"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="dae45b6c-b14a-0233-c200-d69247794930"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="f2eea542-7d8e-d76a-cb21-10886b20f4f4"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="f2eea542-7d8e-d76a-cb21-10886b20f4fa"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="f2eea542-7d8e-d76a-cb21-10886b20f4fe"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="dae45b6c-b14a-0233-c200-d69247794942"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="dae45b6c-b14a-0233-c200-d6924779494b"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="dae45b6c-b14a-0233-c200-d69247794954"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="50e47ce3-9b29-f3b8-87a0-1c5922af1115"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="50e47ce3-9b29-f3b8-87a0-1c5922af111a"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="50e47ce3-9b29-f3b8-87a0-1c5922af1129"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="50e47ce3-9b29-f3b8-87a0-1c5922af1138"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="50e47ce3-9b29-f3b8-87a0-1c5922af1147"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="dce1eda5-1845-ce6a-79e6-0ef264bcf009"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="dce1eda5-1845-ce6a-79e6-0ef264bcf00d"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="dce1eda5-1845-ce6a-79e6-0ef264bcf013"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="dce1eda5-1845-ce6a-79e6-0ef264bcf017"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="dce1eda5-1845-ce6a-79e6-0ef264bcf01f"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="dce1eda5-1845-ce6a-79e6-0ef264bcf028"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="dce1eda5-1845-ce6a-79e6-0ef264bcf032"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="dce1eda5-1845-ce6a-79e6-0ef264bcf03b"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="dce1eda5-1845-ce6a-79e6-0ef264bcf01c"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="ce2c218d-c968-ae82-0f46-c04cea64a688"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="ce2c218d-c968-ae82-0f46-c04cea64a68d"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="ce2c218d-c968-ae82-0f46-c04cea64a696"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="ce2c218d-c968-ae82-0f46-c04cea64a69f"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="a787e6ab-1c79-dbf5-a735-2f94a52b5d8f"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="a787e6ab-1c79-dbf5-a735-2f94a52b5d96"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="a787e6ab-1c79-dbf5-a735-2f94a52b5d9c"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="a787e6ab-1c79-dbf5-a735-2f94a52b5da0"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="a787e6ab-1c79-dbf5-a735-2f94a52b5da5"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="a787e6ab-1c79-dbf5-a735-2f94a52b5dae"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="a787e6ab-1c79-dbf5-a735-2f94a52b5db7"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="1ed0b395-3b05-dd0d-6966-d99e2ff8fa58"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="1ed0b395-3b05-dd0d-6966-d99e2ff8fa5d"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="1ed0b395-3b05-dd0d-6966-d99e2ff8fa66"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="1ed0b395-3b05-dd0d-6966-d99e2ff8fa6f"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="1ed0b395-3b05-dd0d-6966-d99e2ff8fa78"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="5f9f8480-29e7-c633-2304-3c68900e636d"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="5f9f8480-29e7-c633-2304-3c68900e637d"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="5f9f8480-29e7-c633-2304-3c68900e6383"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="5f9f8480-29e7-c633-2304-3c68900e6387"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="c7f598d2-0ac8-9d50-5f79-c102be7b98b4"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="c7f598d2-0ac8-9d50-5f79-c102be7b98bc"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="3553fcc6-17e7-425d-b144-02a0c061aa02"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="3553fcc6-17e7-425d-b144-02a0c061aa0a"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="c7f598d2-0ac8-9d50-5f79-c102be7b98a8"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="6cd6ee58-c9e9-ffdf-06f2-c1cdc1851fc5"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="6cd6ee58-c9e9-ffdf-06f2-c1cdc1851fca"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="6cd6ee58-c9e9-ffdf-06f2-c1cdc1851fd3"] {
          opacity: 0;
        }
        html.w-mod-js:not(.w-mod-ix)
          [data-w-id="6cd6ee58-c9e9-ffdf-06f2-c1cdc1851fdc"] {
          opacity: 0;
        }
      }
           
          `}
        </style>
      </Helmet>
      <BackgorundImageContainer>
      <NavBar />
      <BannerSection />
      </BackgorundImageContainer>
      <GlobalInputAppSection/>
      
      
      <InstrumentationSection/>
      
      <MediaAssetManagerSection/>
      <DeviceSimulatorSection />      
      <Footer />
    </>
  )
}
export default ProductsPage
