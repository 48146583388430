import React from 'react';

function MediaAssetManagerFeatures() {
  return (
    
    <div
    data-w-id='c7f598d2-0ac8-9d50-5f79-c102be7b98a8'
    className='w-layout-grid layout10_component-5 mediaassetbackground'
  >
    <div className='layout10_content'>
      <div className='w-layout-grid layout10_item-list-5'>
        <div
          data-w-id='c7f598d2-0ac8-9d50-5f79-c102be7b98b4'
          className='layout10_item'
        >
          <div className='margin-bottom margin-xsmall'>
            <img src='images/Group.svg' loading='lazy' alt='' />
            <img
              src='images/icon.svg'
              loading='lazy'
              alt=''
              className='icon-1x1-medium'
            />
          </div>
          <div className='margin-bottom margin-xsmall'>
            <h6 className='heading-global-input-app'>
            Media Asset Management
            </h6>
          </div>
          <p className='paragraphglobalinputapp'>
          Comprehensive Media Asset Management designed to minimize vendor lock-in, featuring extensive metadata management while providing operational agility and freedom in content management.
          </p>
        </div>
        <div
          data-w-id='c7f598d2-0ac8-9d50-5f79-c102be7b98bc'
          className='layout10_item'
        >
          <div className='margin-bottom margin-xsmall'>
            <img src='images/management.svg' loading='lazy' alt='' />
            <img
              src='images/icon.svg'
              loading='lazy'
              alt=''
              className='icon-1x1-medium'
            />
          </div>
          <div className='margin-bottom margin-xsmall'>
            <h6 className='heading-global-input-app'>
            Integration with Publishing Platforms
            </h6>
          </div>
          <p className='paragraphglobalinputapp'>
          Integrates with existing back-end services and popular media publishing platforms and implements efficient media workflows with reduced risk of vendor dependency and adaptability for future requirements 
          </p>
        </div>
      </div>
      <div className='w-layout-grid layout10_item-list-5'>
        <div
          data-w-id='3553fcc6-17e7-425d-b144-02a0c061aa02'
          className='layout10_item'
        >
          <div className='margin-bottom margin-xsmall'>
            <img src='images/Group1.svg' loading='lazy' alt='' />
            <img
              src='images/icon.svg'
              loading='lazy'
              alt=''
              className='icon-1x1-medium'
            />
          </div>
          <div className='margin-bottom margin-xsmall'>
            <h6 className='heading-global-input-app'>
            Metadata Management
            </h6>
          </div>
          <p className='paragraphglobalinputapp'>
          Comprehensive metadata management with the ability to maintain detailed metadata in a way that gives business to freedom to process the media and metadata outside the system.
          </p>
        </div>
        <div
          data-w-id='3553fcc6-17e7-425d-b144-02a0c061aa0a'
          className='layout10_item'
        >
          <div className='margin-bottom margin-xsmall'>
            <img src='images/usecases4.svg' loading='lazy' alt='' />
            <img
              src='images/icon.svg'
              loading='lazy'
              alt=''
              className='icon-1x1-medium'
            />
          </div>
          <div className='margin-bottom margin-xsmall'>
            <h6 className='heading-global-input-app'>
            Video Inspection and Quality Control
            </h6>
          </div>
          <p className='paragraphglobalinputapp'>
          The system includes tools for inspecting video assets to ensure they meet the required quality standards before publishing
          </p>
        </div>
      </div>
    </div>
  </div>
  );
}



export default MediaAssetManagerFeatures;