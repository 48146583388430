import React from "react";


import DeviceSimulatorTitle from './DeviceSimulatorTitle';
import DeviceSimulatorDescription from './DeviceSimulatorDescription';

import DeviceFeature from './DeviceFeature';
import SimulatorUseCasesSection from './SimulatorUseCasesSection';



const DeviceSimulatorSection = () => {
    return (
        
        <section className='section_layout10'>
        <div className='padding-global'>                        
                <div className='padding-global '>
                <div className='container-large'>                
                <DeviceSimulatorTitle />
                <DeviceFeature/>    
                <SimulatorUseCasesSection />                    
                <DeviceSimulatorDescription/>                
                </div>
                </div>            
            
        </div>
    </section>
        
        
    );
};
export default DeviceSimulatorSection; 