import React from 'react';

function Instrumentationtitle(){
    return (
        <div
                    data-w-id='a787e6ab-1c79-dbf5-a735-2f94a52b5d8f'
                    className='layout458_content-left'
                  >
                    <h2 className='heading-4 globalinputh'>
                      Instrumentation System
                    </h2>
                  </div>
    );

}
export default Instrumentationtitle;