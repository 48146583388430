import React from 'react'

import NavBar from '../NavBar'
import Footer from '../Footer'
import PrivacySection from './PrivacySection';

function PrivacyPage () {
  return (
    <>
      
      <NavBar />
      <PrivacySection/>
      
      <Footer />
    </>
  )
}
export default PrivacyPage
