import React, { useState } from 'react'
import api from '../api';

function ContactUsWindow () {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [message, setMessage] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const obSend = () => {
    
 const sendMessages = async () => {
    try{
      await api.sendMessages({
        firstName,
        lastName,
        email,
        phoneNumber,
        message
      });
      setIsSubmitted(true);
      setErrorMessage('');
      setIsError(false);            
    }
    catch(e){      
      setIsError(true);
      setErrorMessage(e.message);
    }      
  }    
  sendMessages();
  }  
  return (
    <div
                data-w-id='ca9d13e0-fb92-c3d9-f1b9-307646019f0d'
                className='contact-right-side'
              >

    <div className='margin-bottom margin-large'>
                  <div className='text-align-center-15'>
                    <div className='max-width-large-3 align-center contact-us-max-width'>
                      <div className='margin-bottom margin-small contact-us-margin'>
                        <h2 className='contact-us-heading'>Get in Touch</h2>
                      </div>
                      <p className='text-size-medium-12 contact-us-paragraph'>
                      Send us a message and we will get back to you as soon as
                      possible
                      </p>
                    </div>
                  </div>
                </div>
    <div className='max-width-medium align-center'>
      
      <div className='contact1_component w-form'>
      
        <form
          id='contact-us-form'
          name='wf-form-Contact-Us-Form'
          data-name='Contact Us Form'
          className='contact1_form'
          data-wf-page-id='669cfdf6c6e29f58582da552'
          data-wf-element-id='ca9d13e0-fb92-c3d9-f1b9-307646019f18'
        >
          <div
            id='w-node-ca9d13e0-fb92-c3d9-f1b9-307646019f19-582da552'
            className='contact-us-form-items-wrapper'
          >
            <div className='form_field-wrapper'>
              <label htmlFor='First-Name' className='form_field-label'>
                First name
              </label>
              <input
                className='form_input-2 w-input'
                maxLength='256'
                name='First-Name'
                data-name='First Name'
                placeholder=''
                type='text'
                id='First-Name'
                required=''
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </div>
            <div className='form_field-wrapper'>
              <label htmlFor='Last-Name' className='form_field-label'>
                Last Name
              </label>
              <input
                className='form_input-2 w-input'
                maxLength='256'
                name='Last-Name'
                data-name='Last Name'
                placeholder=''
                type='text'
                id='Last-Name'
                required=''
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div
            id='w-node-ca9d13e0-fb92-c3d9-f1b9-307646019f22-582da552'
            className='contact-us-form-items-wrapper'
          >
            <div className='form_field-wrapper'>
              <label htmlFor='Business-Email' className='form_field-label'>
                Business email
              </label>
              <input
                className='form_input-2 w-input'
                maxLength='256'
                name='Business-Email'
                data-name='Business Email'
                placeholder=''
                type='email'
                id='Business-Email'
                required=''
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className='form_field-wrapper'>
              <label htmlFor='phone_number' className='form_field-label'>
                Phone number
              </label>
              <input
                className='form_input-2 w-input'
                maxLength='256'
                name='Phone-Number'
                data-name='Phone Number'
                placeholder=''
                type='tel'
                id='phone_number'
                required=''
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
              />
            </div>
          </div>
          <div className='form_field-wrapper'>
            <label htmlFor='Message' className='form_field-label'>
              Message
            </label>
            <textarea
              id='Message'
              name='Message'
              maxLength='5000'
              data-name='Message'
              placeholder=''
              required=''
              className='form_input-2 is-text-area w-input'
              value={message}
              onChange={e => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div className='margin-bottom margin-xsmall'></div>
          <input
            type='button'
            data-wait='Please wait...'
            id='w-node-ca9d13e0-fb92-c3d9-f1b9-307646019f35-582da552'
            data-w-id='ca9d13e0-fb92-c3d9-f1b9-307646019f35'
            className='button-18 contact-us-submit-button w-button'
            value='Send'
            onClick={obSend}
          />
          
        </form>        
        {isSubmitted && (
          <div className='success-message-2 w-form-done'>
            <div className='success-text-2'>
              Thank you! Your submission has been received!
            </div>
          </div>
        )}
        {isError && (
          <div className='error-message-2 w-form-fail'>
            <div className='error-text-2'>
              Oops! Something went wrong while submitting the form.
            </div>
          </div>
        )}
      </div>
    </div>
    </div>
  )
}
export default ContactUsWindow
