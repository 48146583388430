import React from 'react'

import NavBar from '../NavBar'
import Footer from '../Footer'
import { Helmet } from 'react-helmet-async'
import ContactUsWindow from './ContactUsWindow'
import ContactUsCard from './ContactUsCard'
import styled from 'styled-components'

const RightMessageContainer=styled.div`

@media  (max-width: 991px) {
  display:none;
}


`;
const BottomMessageContainer=styled.div`
margin-top: 2rem;
@media  (min-width: 992px) {
  display:none;
}
  

`;

function PageContent () {
  return (
    <header className='section_header64-2 contact-header' id="contactTop">
      <div className='padding-global-17'>
      <div className='padding-global'>
      <div className='layout484_component'>
              <h2
                data-w-id='e39683da-6faa-fb25-807e-1bb9b1352a06'
                className='layout484_text'
              >
                Contact Us
              </h2>              
      </div>
      </div>
      



        <div className='container-small contact-us-container-smaller'>
          <div className='padding-section-large-6 training-hub-padding contact-us-padding'>
            <div
              data-w-id='ca9d13e0-fb92-c3d9-f1b9-307646019ed4'
              className='text-align-center-15 contact-us-div-wrapper contact-us-text-align-tablet'
            >
                <ContactUsCard />                              
             <RightMessageContainer>
             <ContactUsWindow />              
              </RightMessageContainer>   
                
            </div>
          </div>
        </div>
        <BottomMessageContainer>
        <ContactUsWindow />
        </BottomMessageContainer>
      </div>
    </header>
  )
}
function ContactUs () {
  return (
    <>
      <Helmet>
        <style>
          {`
              @media (min-width: 992px) {
            html.w-mod-js:not(.w-mod-ix) [data-w-id="ca9d13e0-fb92-c3d9-f1b9-307646019ed9"] {
                opacity: 0;
            }

            html.w-mod-js:not(.w-mod-ix) [data-w-id="ca9d13e0-fb92-c3d9-f1b9-307646019f0d"] {
                opacity: 0;
            }

            html.w-mod-js:not(.w-mod-ix) [data-w-id="ca9d13e0-fb92-c3d9-f1b9-307646019edb"] {
                opacity: 0;
            }

            html.w-mod-js:not(.w-mod-ix) [data-w-id="ca9d13e0-fb92-c3d9-f1b9-307646019ed4"] {
                opacity: 0;
            }

            html.w-mod-js:not(.w-mod-ix) [data-w-id="ca9d13e0-fb92-c3d9-f1b9-307646019ed6"] {
                opacity: 0;
            }

            html.w-mod-js:not(.w-mod-ix) [data-w-id="cff33a5a-f95c-2503-99da-13922a51cf3b"] {
                opacity: 0;
            }

            html.w-mod-js:not(.w-mod-ix) [data-w-id="edbb04f1-f4a0-2727-f84c-2dcaa1dfe419"] {
                opacity: 0;
            }

            html.w-mod-js:not(.w-mod-ix) [data-w-id="a0d7f4d4-5097-239f-f090-afeb024a8fdc"] {
                opacity: 0;
            }
        }

        @media (max-width: 991px) and (min-width: 768px) {
            html.w-mod-js:not(.w-mod-ix) [data-w-id="ca9d13e0-fb92-c3d9-f1b9-307646019ed9"] {
                opacity: 0;
            }

            html.w-mod-js:not(.w-mod-ix) [data-w-id="ca9d13e0-fb92-c3d9-f1b9-307646019f0d"] {
                opacity: 0;
            }

            html.w-mod-js:not(.w-mod-ix) [data-w-id="ca9d13e0-fb92-c3d9-f1b9-307646019edb"] {
                opacity: 0;
            }

            html.w-mod-js:not(.w-mod-ix) [data-w-id="ca9d13e0-fb92-c3d9-f1b9-307646019ed4"] {
                opacity: 0;
            }

            html.w-mod-js:not(.w-mod-ix) [data-w-id="ca9d13e0-fb92-c3d9-f1b9-307646019ed6"] {
                opacity: 0;
            }
        }
          `}
        </style>
      </Helmet>
      <NavBar />
      <PageContent />
      <Footer />
    </>
  )
}
export default ContactUs
