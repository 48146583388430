import React from "react";

import { NavLink } from 'react-router-dom';
function Footer () {
  return (
    <footer
      data-w-id='d87ffd53-c045-47c4-d97b-0a4d92c58bbc'
      className='footer7_component'
    >
      <div className='padding-global'>
        <div className='container-large'>
          <div className='padding-vertical padding-xxlarge footer-padding'>
            {/*   <div   className="padding-bottom padding-xxlarge">
              <div   className="fotterachievegoals">
                <img
                  src="images/Polygon-22.svg"
                  loading="eager"
                  alt=""
                    className="image-4"
                />
                <a href="contact-us.html"   className="link-block-3 w-inline-block">
                  <div   className="footerbutton">Achieve your goals</div>
                </a>
              </div>
              <div   className="footer7_top-wrapper">
                <a
                  href="index.html"
                  id="w-node-d87ffd53-c045-47c4-d97b-0a4d92c58bc7-92c58bbc"
                  aria-current="page"
                    className="footer7_logo-link w-nav-brand w--current"
                  ><img src="images/logo-2-1.svg" loading="eager" alt=""
                /></a>
                <div   className="w-layout-grid footer7_link-list">
                  <a
                    href="index.html"
                    aria-current="page"
                      className="footer7_link w--current"
                    >Home</a
                  >
                  <a href="solutions.html"   className="footer7_link">Solutions</a>
                  <a href="products.html"   className="footer7_link">Products</a>
                </div>
              </div>
              <div   className="div-block-4">
                <img
                  src="images/Polygon-12.svg"
                  loading="eager"
                  alt=""
                    className="image-5"
                />
                <a
                  href="contact-us.html"
                    className="link-block-3 footerrightbutton w-inline-block"
                >
                  <div   className="footerbutton">Contact us</div>
                </a>
              </div>
            </div>   */}
            <div className='line-divider'></div>
            <div className='padding-top padding-medium'>
              <div className='footer7_bottom-wrapper'>
                <div className='footer-wrapper'>
                   <div   className="footer7_credit-text">
                    © Iterativesolution Limited 2024
                  </div>   
                  <NavLink to='/privacy' className='footer7_legal-link footercolor'>
                    Privacy Policy
                  </NavLink>

                  
                  {/*   <a href="#"   className="footer7_legal-link">Terms of Service</a>   */}
                </div>
                <div className='w-layout-grid footer7_legal-list'>
                  {/*   <img
                    src="images/Facebook.svg"
                    loading="lazy"
                    id="w-node-d87ffd53-c045-47c4-d97b-0a4d92c58be0-92c58bbc"
                    alt=""
                  /><img
                    src="images/Instagram.svg"
                    loading="lazy"
                    id="w-node-d87ffd53-c045-47c4-d97b-0a4d92c58be1-92c58bbc"
                    alt=""
                  /><img
                    src="images/X.svg"
                    loading="lazy"
                    id="w-node-d87ffd53-c045-47c4-d97b-0a4d92c58be2-92c58bbc"
                    alt=""
                  />   */}
                  <a
                    href='https://www.linkedin.com/company/10531755/admin/dashboard/'
                    alt='LinkedIn link'
                  >
                    <img
                      src='images/LinkedIn.svg'
                      loading='lazy'
                      id='w-node-d87ffd53-c045-47c4-d97b-0a4d92c58be3-92c58bbc'
                      alt=''
                    />
                  </a>
                  <a
                    href='https://www.youtube.com/channel/UCwzhIv8bIYCdcldnhGckUJg'
                    alt='Youtube link'
                  >
                    <img
                      src='images/Youtube.svg'
                      loading='lazy'
                      id='w-node-d87ffd53-c045-47c4-d97b-0a4d92c58be4-92c58bbc'
                      alt=''
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
