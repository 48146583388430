import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import HomePage from './home'
import { HelmetProvider } from 'react-helmet-async'

import SolutionPage from './solutions'
import ProductPage from './products'
import ContactUs from './contact-us'
import PrivacyPage from './privacy'
function App () {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path='/solutions' element={<SolutionPage />} />
          <Route path='/solutions.html' element={<SolutionPage />} />
          <Route path='/products' element={<ProductPage />} />
          <Route path='/products.html' element={<ProductPage />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/contact-us.html' element={<ContactUs />} />
          <Route path='/' element={<HomePage />} />
          <Route path='/index.html' element={<HomePage />} />
          <Route path='/privacy' element={<PrivacyPage />} />
          <Route path='/privacy.html' element={<PrivacyPage />} />
        </Routes>
      </Router>
    </HelmetProvider>
  )
}

export default App
