import React from 'react'

function GlobalInputAppUseCasesSection () {
  return (
    <section className='section_layout305 usecase1padding'>
      
        
          
              <div
                data-w-id='50e47ce3-9b29-f3b8-87a0-1c5922af1115'
                className='margin-bottom margin-xxlarge'
              >                
              </div>
              <div className='w-layout-grid layout305_list'>
                <div
                  id='w-node-_50e47ce3-9b29-f3b8-87a0-1c5922af111a-08f35c86'
                  data-w-id='50e47ce3-9b29-f3b8-87a0-1c5922af111a'
                  className='layout305_item'
                >
                  <div className='layout305_item-icon-wrapper'>
                    <img
                      src='images/usecases3.svg'
                      loading='lazy'
                      width='Auto'
                      alt=''
                      className='icon-1x1-medium'
                    />
                  </div>
                  <div className='layout305_item-text-wrapper'>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-style-h5-2 usecasesheading'>
                        Securing Data
                      </h3>
                    </div>
                    <p className='usecaseparag'>
                    Encrypting data stored in insecure storages and databases, ensuring that it can only be unlocked by the user’s mobile device.
                    </p>
                  </div>
                </div>
                <div
                  id='w-node-_50e47ce3-9b29-f3b8-87a0-1c5922af1129-08f35c86'
                  data-w-id='50e47ce3-9b29-f3b8-87a0-1c5922af1129'
                  className='layout305_item'
                >
                  <div className='layout305_item-icon-wrapper'>
                    <img
                      src='images/usecases1.svg'
                      loading='lazy'
                      alt=''
                      className='icon-1x1-medium'
                    />
                  </div>
                  <div className='layout305_item-text-wrapper'>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-style-h5-2 usecasesheading'>
                      Customer Collaborations
                      </h3>
                    </div>
                    <p className='usecaseparag'>
                    Integrating secure, on-demand customer collaboration directly into existing business applications without requiring changes to existing back-end services.

                    
                    </p>
                  </div>
                </div>
                <div
                  id='w-node-_50e47ce3-9b29-f3b8-87a0-1c5922af1138-08f35c86'
                  data-w-id='50e47ce3-9b29-f3b8-87a0-1c5922af1138'
                  className='layout305_item'
                >
                  <div className='layout305_item-icon-wrapper'>
                    <img
                      src='images/usecases4.svg'
                      loading='lazy'
                      alt=''
                      className='icon-1x1-medium'
                    />
                  </div>
                  <div className='layout305_item-text-wrapper'>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-style-h5-2 usecasesheading'>
                        IoT Integration
                      </h3>
                    </div>
                    <p className='usecaseparag'>
                    Integrating mobile into IoT applications to enable secure and intuitive interactions with IoT devices, eliminating the need to develop additional back-end services.
                    </p>
                  </div>
                </div>
                <div
                  id='w-node-_50e47ce3-9b29-f3b8-87a0-1c5922af1147-08f35c86'
                  data-w-id='50e47ce3-9b29-f3b8-87a0-1c5922af1147'
                  className='layout305_item'
                >
                  <div className='layout305_item-icon-wrapper'>
                    <img
                      src='images/usecases2.svg'
                      loading='lazy'
                      alt=''
                      className='icon-1x1-medium'
                    />
                  </div>
                  <div className='layout305_item-text-wrapper'>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-style-h5-2 usecasesheading'>
                        Secure Offline Access
                      </h3>
                    </div>
                    <p className='usecaseparag'>
                    Providing secure offline access to sensitive information like passwords and encryption keys, simplifying the process of backing up or exchanging crucial data securely.
                    </p>
                  </div>
                </div>
              </div>
            
          
        
      
    </section>
  )
}

export default GlobalInputAppUseCasesSection
