import React from 'react'

function InstrumentationUseCases () {
  return (                
              <div className='w-layout-grid layout305_list'>
                <div
                  id='w-node-_1ed0b395-3b05-dd0d-6966-d99e2ff8fa5d-08f35c86'
                  data-w-id='1ed0b395-3b05-dd0d-6966-d99e2ff8fa5d'
                  className='layout305_item'
                >
                  <div className='layout305_item-icon-wrapper'>
                    <img
                      src='images/usecases3.svg'
                      loading='lazy'
                      alt=''
                      className='icon-1x1-medium'
                    />
                  </div>
                  <div className='layout305_item-text-wrapper'>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-style-h5-2 usecasesheading'>
                      Automated Testing
                      </h3>
                    </div>
                    <p className='usecaseparag'>
                     
                     
                     Executes automated test cases by simulating various user interactions and scenarios on actual devices, including simulating delayed or unusual backend service responses, media streams, and DRM licenses.
                     </p>
                  </div>
                </div>
                <div
                  id='w-node-_1ed0b395-3b05-dd0d-6966-d99e2ff8fa66-08f35c86'
                  data-w-id='1ed0b395-3b05-dd0d-6966-d99e2ff8fa66'
                  className='layout305_item'
                >
                  <div className='layout305_item-icon-wrapper'>
                    <img
                      src='images/usecases1.svg'
                      loading='lazy'
                      alt=''
                      className='icon-1x1-medium'
                    />
                  </div>
                  <div className='layout305_item-text-wrapper'>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-style-h5-2 usecasesheading'>
                      Issue Diagnosis
                      </h3>
                    </div>
                    <p className='usecaseparag'>                      
                    Analyzes the root cause of issues by correlating application logs and interactions between client applications and backend services with the application code.
                    </p>
                  </div>
                </div>
                <div
                  id='w-node-_1ed0b395-3b05-dd0d-6966-d99e2ff8fa6f-08f35c86'
                  data-w-id='1ed0b395-3b05-dd0d-6966-d99e2ff8fa6f'
                  className='layout305_item'
                >
                  <div className='layout305_item-icon-wrapper'>
                    <img
                      src='images/usecases4.svg'
                      loading='lazy'
                      alt=''
                      className='icon-1x1-medium'
                    />
                  </div>
                  <div className='layout305_item-text-wrapper'>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-style-h5-2 usecasesheading'>
                      Solution Validation
                      </h3>
                    </div>
                    <p className='usecaseparag'>
                    Injects code for suggested solutions, validating them on actual devices, and simulating backend services if necessary, all without requiring changes to the code repository.
                    </p>
                  </div>
                </div>
                <div
                  id='w-node-_1ed0b395-3b05-dd0d-6966-d99e2ff8fa78-08f35c86'
                  data-w-id='1ed0b395-3b05-dd0d-6966-d99e2ff8fa78'
                  className='layout305_item'
                >
                  <div className='layout305_item-icon-wrapper'>
                    <img
                      src='images/usecases2.svg'
                      loading='lazy'
                      alt=''
                      className='icon-1x1-medium'
                    />
                  </div>
                  <div className='layout305_item-text-wrapper'>
                    <div className='margin-bottom margin-xsmall'>
                      <h3 className='heading-style-h5-2 usecasesheading'>
                      Independent R&D 
                      </h3>
                    </div>
                    <p className='usecaseparag'>
                    
                    
                    Allows research and development teams to independently inject and augment functionalities into client applications and backend services at deployment time without requiring changes to the code repositories, enabling separate innovation cycles.
                      
                    </p>
                  </div>
                </div>
              </div>





  )
}

export default InstrumentationUseCases
